import React from "react";
import "../../styles/servicehomepage.css";
import {
  AppLayout,
  Box
} from "../../aws-ui-components";

import HomeContent from "./HomeContent";
import HomeNav from "./HomeNav";
import {connect} from "react-redux";
import {getUser} from "../../actions";
import MainTools from "./MainTools";

class Home extends React.Component {

  constructor(props) {
    super(props);
    //console.log(this);
  }

  render() {
    return (
      <div>
        <Box>
          <AppLayout
            disableContentPaddings={true}
            navigation={<HomeNav />}
            content={<HomeContent />}
            navigationOpen={true}
            tools={<MainTools/>}
            toolsHide={false}
          />
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {state};
}

export default connect(mapStateToProps, {
getUser
})(Home);

