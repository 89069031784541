import React from 'react';
import {connect} from "react-redux";
import XLSX from 'xlsx';
import {Alert, Box, Button, Container, Grid, Header, Modal, SpaceBetween, Tabs} from "../../../aws-ui-components";
import DynamicForm from "../resources/form/DynamicForm";
import {fetchSession, updateExcelUploadState, updatePreQ, updatePreQUpload, updateSession} from "../../../actions";

class WBWPreQ extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabValues: [],
      modalUploadVisible: false,
      uploadSubDisabled: true,
      selectedUploadFile: "",
      excelUploadState: false,
      excelException: false
    }
  }

  componentDidMount() {
    let tabs = [];

    if (this.props.store.sessionInfo !== undefined)
      if (this.props.store.sessionInfo.session !== undefined)
        if (this.props.store.sessionInfo.session.sessionForms !== undefined)
          for (let x of this.props.store.sessionInfo.session.sessionForms.wbw.preQ) {
            tabs.push({
              label: x.label,
              id: x._uid,
              content: <DynamicForm parent={this.state} formData={[x]} handleChange={this.handleChange} updateEventChange={this.props.updateEventChange} saveToDB={this.props.saveToDB}/>
            });
          }
    this.setState({tabValues: tabs})
  };

  // Prepare Excel for Download ---->
  getExcelRows(c1, c2, data) {
    const answers = (this.props.store.sessionInfo.session.answers.preq) ? this.props.store.sessionInfo.session.answers.preq : [];
    let component;
    if (data.component !== undefined && data.component !== null) {
      component = data.component;
    } else {
      component = "sheet";
    }

    switch (component) {
      case "sheet":
        let finRetData = [];
        for (let field in data) {
          finRetData = finRetData.concat(this.getExcelRows("", "", data[field]));
        }
        return finRetData

      case "tab":
        let retTabData = [];
        for (let field in data.fields) {
          retTabData = retTabData.concat(this.getExcelRows(data.label, "", data.fields[field]));
        }
        return retTabData;

      case "field_group":
        let rows = [];
        for (let field in data.fields) {
          let answert = "";
          if (data.fields[field] !== undefined) {
            answert = data.fields[field]
          }
          rows.push(this.getExcelRows(c1, data.label, answert));
        }
        return rows;

      case "testarea":
        return (
          {
            Topic: c1,
            SubTopic: c2,
            qid: data._uid,
            type: data.component,
            Question: data.label,
            Answer: (answers[data._uid]) ? answers[data._uid].answer : "",
            Options: "",
            Instructions: "Do not use quotation marks."
          }
        )

      case "radio":
        let readioOptions = "";
        data.options.map(option => {
          readioOptions = option.value + ", " + readioOptions;
        })
        return (
          {
            Topic: c1,
            SubTopic: c2,
            qid: data._uid,
            type: data.component,
            Question: data.label,
            Answer: (answers[data._uid]) ? answers[data._uid].answer : "",
            Options: readioOptions,
            Instructions: "Select only one option."
          }
        )

      case "select":
        let selOptions = "";
        data.options.map(option => {
          selOptions = option.value + ", " + selOptions;
        })

        let properAnswerSel = "";
        if (answers[data._uid]) {
          for (let a in answers[data._uid].answer) {
            properAnswerSel = answers[data._uid].answer[a].value + ", " + properAnswerSel;
          }
        }

        return (
          {
            Topic: c1,
            SubTopic: c2,
            qid: data._uid,
            type: data.component,
            Question: data.label,
            Answer: properAnswerSel,
            Options: selOptions,
            Instructions: "Select options (comma delimited)."
          }
        )

      case "mselect":
        let mSelOptions = "";
        data.options.map(option => {
          if (mSelOptions !== "") {
            if (option.value.trim() !== "") {
              mSelOptions = mSelOptions + "," + option.value.trim();
            }
          } else {
            if (option.value.trim() !== "") {
              mSelOptions = option.value.trim();
            }
          }
        })

        let properAnswermSel = "";
        if (answers[data._uid]) {
          for (let a in answers[data._uid].answer) {
            if (properAnswermSel !== "") {
              if (answers[data._uid].answer[a].value.trim() !== "") {
                properAnswermSel = properAnswermSel + "," + answers[data._uid].answer[a].value.trim();
              }
            } else {
              if (answers[data._uid].answer[a].value.trim() !== "") {
                properAnswermSel = answers[data._uid].answer[a].value.trim();
              }
            }
          }
        }

        return (
          {
            Topic: c1,
            SubTopic: c2,
            qid: data._uid,
            type: data.component,
            Question: data.label,
            Answer: properAnswermSel,
            Options: mSelOptions,
            Instructions: "Select options (comma delimited)."
          }
        )

      case "input":
        return (
          {
            Topic: c1,
            SubTopic: c2,
            qid: data._uid,
            type: data.component,
            Question: data.label,
            Answer: (answers[data._uid]) ? answers[data._uid].answer : "",
            Options: "",
            Instructions: "Free form text.  Do not use quotation marks."
          }
        )
    }
  }

  handleExcelDownload = (event) => {
    const excelRow = this.getExcelRows("", "", this.props.store.sessionInfo.session.sessionForms.wbw.preQ);

    //console.log(excelRow);

    const ws = XLSX.utils.json_to_sheet(excelRow);
    ws['!cols'] = [{wch: 30}, {wch: 30}, {wpx: 0}, {wpx: 0}, {wch: 80}, {wch: 70}, {wch: 50}, {wch: 50}]

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Questionnaire");
    XLSX.writeFile(wb, this.props.store.sessionInfo.session.sessionID + ".xlsx");
  }

  // Upload PreQ Excel Sheet
  handleExcelUpload = (event) => {
    this.setState({modalUploadVisible: true});
  }

  onUploadFileSelect = (event) => {
    this.setState({uploadSubDisabled: false});
    this.setState({selectedUploadFile: event.target.files[0]});
  }

  handleFile = (event) => {
    const oFile = this.state.selectedUploadFile;
    const reader = new FileReader();

    reader.onload = async (e) => {
      let res = {preq: []};
      let data = e.target.result;
      data = new Uint8Array(data);
      let workbook = XLSX.read(data, {type: 'array'});
      //console.log(workbook);
      let result = {};
      workbook.SheetNames.forEach(function (sheetName) {
        let roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1});
        if (roa.length) result[sheetName] = roa;
      });

      if (result["Questionnaire"]) {
        for (let row in result["Questionnaire"]) {
          let answer;
          if (result["Questionnaire"][row][5] !== "" && result["Questionnaire"][row][5] !== null) {
            if (result["Questionnaire"][row][3] === "select" || result["Questionnaire"][row][3] === "mselect") {
              const s = (result["Questionnaire"][row][5]).split(",");
              //console.log(s);
              const a = [];
              for (let item in s) {
                a.push({label: s[item].trim(), value: s[item].trim()});
              }
              answer = a;
            } else {
              answer = result["Questionnaire"][row][5];
            }

            if (row > 0) {
              res.preq[result["Questionnaire"][row][2]] = {
                "question": result["Questionnaire"][row][4],
                "answer": answer
              };
            }
          }
        }

        let preqs;
        if (this.props.store.sessionInfo.session !== undefined)
          if (this.props.store.sessionInfo.session.answers !== undefined)
            if (this.props.store.sessionInfo.session.answers.preq !== undefined)
              preqs = {preq:{...this.props.store.sessionInfo.session.answers.preq, ...res.preq}}

        this.setState(preqs || res);

        await this.props.updatePreQUpload(this).then(() => {
          this.setState({updateExcelUploadState: true});
          this.props.updateExcelUploadState({updateExcelUploadState: true});
          this.setState({modalUploadVisible: false});
          this.props.updateEventChange(true);
          this.props.saveToDB();
        });
      } else {
        this.setState({modalUploadVisible: false});
        this.setState({excelException: true})
      }
    };
    reader.readAsArrayBuffer(oFile);
  }

  render() {
    return (
      <div>
        <Alert
          visible={this.state.excelException}
          dismissAriaLabel="Close alert"
          dismissible
          type="warning"
        >
          Probably, the excel sheet you uploaded is not correct!
        </Alert>

        <Box padding={{top: "l"}} margin="l">
            <div>
              <Modal
                visible={this.state.modalUploadVisible}
                onDismiss={() => this.setState({modalUploadVisible: false})}
                closeAriaLabel="Close modal"
                size="medium"
                header="Replace Pre-Questionnaire Answers from an Excel File"
                footer={
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button variant="primary"
                              disabled={this.state.uploadSubDisabled}
                              onClick={async detail => {
                                await this.handleFile(detail)
                              }}
                      >Upload</Button>
                    </SpaceBetween>
                  </Box>
                }
              >
                <SpaceBetween size="m">
                  <Box color="text-status-error">
                    Note: This action will permanently update all answers from the excel file.
                  </Box>
                  <Box color="text-status-error">
                    Note: Once the file is processed, application renders back to the main screen.
                  </Box>
                  <Box color="text-body-secondary">
                    This file is processed client side and only the relevant data is uploaded
                    to the server.
                  </Box>
                  <input type="file" name="file" onChange={this.onUploadFileSelect}/>
                </SpaceBetween>
              </Modal>

              <Container
                header={
                  <Header
                    variant="h2"
                    description=""
                    actions={
                      <SpaceBetween
                        direction="horizontal"
                        size="xs"
                      >
                        <Button iconName="download" variant="normal"
                                onClick={detail => {
                                  this.handleExcelDownload(detail)
                                }}
                        >
                          Export to Excel
                        </Button>
                        <Button iconName="upload" variant="primary"
                                onClick={detail => {
                                  this.handleExcelUpload(detail)
                                }}
                        >
                          Upload Excel
                        </Button>
                      </SpaceBetween>
                    }
                  >
                    Pre-Questionnaire
                  </Header>
                }
              >
                <Tabs
                  tabs={this.state.tabValues}
                  handleChange={this.handleChange}
                />
              </Container>
            </div>
        </Box>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updatePreQUpload, updatePreQ, updateSession, updateExcelUploadState
})(WBWPreQ);