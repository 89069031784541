import React from 'react';
import {Tabs, Box, Container, Grid, Header, Textarea} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession, updateStr, updateSession} from "../../../actions";
import {Alert, Toggle} from "@amzn/awsui-components-react";

class PartnerGTM extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partnerVision: {

      },
      edit:false
    }

  }

  handleTextChange = (name, value) => {
  }
  handleTextSave = (name, value) => {
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  render() {
    return(
      <div>
        <Box float="right" margin="xs">
          <Toggle
            onChange={({detail}) =>
              this.setState({edit: detail.checked})
            }
            checked={this.state.edit}
          >
            Edit
          </Toggle>
        </Box>
        <Tabs
          tabs={[
            {
              label: "Go to Market",
              id: "Go to Mar=ket",
              content:   <div>
                <Box margin="l">
                  <Container
                  >

                  </Container>
                </Box>
              </div>
            },
            ]}
          />
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateStr, updateSession
})(PartnerGTM);