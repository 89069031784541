import React from "react";
import { Select, Box } from "../../../../aws-ui-components";

const Radio = ({ field, fieldChanged, fieldBlur, type, value }) => {
  return (
    <Box padding={{ top: "xs" }}>
      <div key={field._uid}>
        <Box>
          <label htmlFor={field._uid}>{field.label}</label>
        </Box>
        <Box>
          <Select
            id={field._uid}
            name={field._uid}
            selectedOption={value.answer}
            options={field.options}
            onChange={detail => {
              fieldChanged(field._uid, detail.detail.selectedOption, field.label, "select", field.options);
            }}
            onBlur={() => {
              fieldBlur(value);
            }}
          />
        </Box>
      </div>
    </Box>
  );
};

export default Radio;
