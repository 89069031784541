import React from 'react';
import {Box, Button, ColumnLayout, Container, Grid, Modal, SpaceBetween, Spinner, Table} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession} from "../../../actions";
import headerimg from "./resources/images/narrative-header.png";
import WBWNarrativeDoc from "./WBWNarrativeDoc";

class WBWNarrative extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      pdfStatus: false
    }
    this.props.turnOffFlashBar();
  }

  sortByProperty(property) {
    return function (a, b) {
      if (a[property] > b[property])
        return 1;
      else if (a[property] < b[property])
        return -1;
      return 0;
    }
  }

  componentDidMount() {
    if ((this.props.selectedSessionInfo) !== undefined) {
      if (this.props.selectedSessionInfo.sessionInfo !== undefined) {
        if (this.props.selectedSessionInfo.sessionInfo.session !== undefined) {
          if (this.props.selectedSessionInfo.sessionInfo.session.answers !== undefined) {
            if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations !== undefined) {
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.OrgAlign !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.OrgAlign);
              }
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.GTM !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.GTM);
              }
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.ProcessOps !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.ProcessOps);
              }
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.SolDev !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.SolDev);
              }
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.StaffProf !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.StaffProf);
              }
            }
          }
        }
      }
    }
    this.state.items.sort(this.sortByProperty("When"));
  }

  render() {

    let ExecutiveSummary = "";
    let StrategicApproach = "";
    let RecommendationsGTM = [];
    let RecommendationsOrgAlign = [];
    let RecommendationsProcessOps = [];
    let RecommendationsSolDev = [];
    let RecommendationsStaff = [];
    let Conclusion = [];
    let SWOTStrengths = [];
    let SWOTWeaknesses = [];
    let SWOTOpportunities = [];
    let SWOTThreats = [];
    let UNICurrent = [];
    let UNIFuture = [];
    let Opportunities = [];
    let PRStmtCurrent = "";
    let PRStmtFuture = "";
    let PRFAQCust = [];
    let PRFAQSH = [];

    if (this.props.selectedSessionInfo.sessionInfo.session !== undefined)
      if (this.props.selectedSessionInfo.sessionInfo.session.answers !== undefined) {
        const answers = this.props.selectedSessionInfo.sessionInfo.session.answers;
        if (answers.str !== undefined) {
          if (answers.str.overview !== undefined) {
            ExecutiveSummary = answers.str.overview
          }
          if (answers.str.approach !== undefined) {
            StrategicApproach = answers.str.approach
          }
          if (answers.str.conclusion !== undefined) {
            Conclusion = answers.str.conclusion
          }
        }

        if (answers.recommendations !== undefined) {
          if (answers.recommendations.GTM !== undefined) {
            RecommendationsGTM = answers.recommendations.GTM
          }
          if (answers.recommendations.OrgAlign !== undefined) {
            RecommendationsOrgAlign = answers.recommendations.OrgAlign
          }
          if (answers.recommendations.ProcessOps !== undefined) {
            RecommendationsProcessOps = answers.recommendations.ProcessOps
          }
          if (answers.recommendations.SolDev !== undefined) {
            RecommendationsSolDev = answers.recommendations.SolDev
          }
          if (answers.recommendations.StaffProf !== undefined) {
            RecommendationsStaff = answers.recommendations.StaffProf
          }
        }
        if (answers.swot !== undefined) {
          if (answers.swot.strengths !== undefined) {
            SWOTStrengths = answers.swot.strengths
          }
          if (answers.swot.weaknesses !== undefined) {
            SWOTWeaknesses = answers.swot.weaknesses
          }
          if (answers.swot.opportunities !== undefined) {
            SWOTOpportunities = answers.swot.opportunities
          }
          if (answers.swot.threats !== undefined) {
            SWOTThreats = answers.swot.threats
          }
        }

        if (answers.uni !== undefined) {
          if (answers.uni.currentUniSPs !== undefined) {
            UNICurrent = answers.uni.currentUniSPs
          }
          if (answers.uni.futureUniSPs !== undefined) {
            UNIFuture = answers.uni.futureUniSPs
          }
        }

        if (answers.opportunities !== undefined) {
          if (answers.opportunities.opportunities !== undefined) {
            Opportunities = answers.opportunities.opportunities
          }
        }

        if (answers.prfaq !== undefined) {
          if (answers.prfaq.CustomerFAQ !== undefined) {
            PRFAQCust = answers.prfaq.CustomerFAQ
          }
        }
        if (answers.prfaq !== undefined) {
          if (answers.prfaq.StakeholderFAQ !== undefined) {
            PRFAQSH = answers.prfaq.StakeholderFAQ
          }
        }

        if (answers.prs !== undefined) {
          if (answers.prs.currentquote !== undefined) {
            PRStmtCurrent = answers.prs.currentquote
          }
          if (answers.prs.futurequote !== undefined) {
            PRStmtFuture = answers.prs.futurequote
          }
        }
      }

    const divStyle = {
      backgroundImage: `url(${headerimg})`,
      width: '100%',
      backgroundPosition: 'center',
      backgroundRepeat: "no-repeat",
      height: '120px',
      backgroundSize: 'cover'
    };

    return (
      <div>
          <Modal
            visible={this.state.pdfStatus}
            size="small"
          >
            Preparing PDF. Please wait...
            <Spinner size="large" variant="normal"/>
          </Modal>

          <Container
            header={
              <div className="centered" style={divStyle}>
                <Box float="right">
                  <WBWNarrativeDoc/>
                </Box>
                <Box>
                  <li/>
                  <h1 style={{color: "#ffffff", textAlign: "center"}}>
                    Working Backwards Workshop Narrative
                  </h1>
                  <p style={{color: "#888888", textAlign: "center"}}> (Recommendations and Roadmap) </p>
                </Box>
              </div>
            }
          >
            <div>
              <ColumnLayout columns={1}>
                <div id="Exec Summary">
                  <h2> Executive Summary</h2>
                  <hr/>
                  <NewlineText
                    text={ExecutiveSummary}
                  />
                </div>
                <div id="Strategic Approach and Focus Areas">
                  <h2> Strategic Approach and Focus Areas </h2>
                  <hr/>
                  <NewlineText
                    text={StrategicApproach}
                  />
                </div>

                <div id="Recommendations">
                  <h2> Recommendations </h2>
                  <hr/>
                  {(RecommendationsOrgAlign !== null && RecommendationsOrgAlign.length > 0) ?
                    <Container header="Organizational Alignment">
                      <ColumnLayout borders="horizontal" columns={1}>
                        {RecommendationsOrgAlign.map((item, index) => (
                          <RecomDiv key={index.toString()}
                                    props={
                                      {
                                        what: item.what,
                                        When: item.When,
                                        rationale: item.rationale,
                                        outcome: item.outcome,
                                        awsinv: item.awsinv,
                                        nextsteps: item.nextsteps
                                      }
                                    }
                          />
                        ))}
                      </ColumnLayout>
                    </Container> : <div></div>
                  }
                  <p/>
                  {(RecommendationsSolDev !== null && RecommendationsSolDev.length > 0) ?
                    <Container header="Practice / Solution Development">
                      <ColumnLayout borders="horizontal" columns={1}>
                        {RecommendationsSolDev.map((item, index) => (
                          <RecomDiv key={index.toString()}
                                    props={
                                      {
                                        what: item.what,
                                        When: item.When,
                                        rationale: item.rationale,
                                        outcome: item.outcome,
                                        awsinv: item.awsinv,
                                        nextsteps: item.nextsteps
                                      }
                                    }
                          />
                        ))}
                      </ColumnLayout>
                    </Container> : <div></div>
                  }
                  <p/>
                  {(RecommendationsGTM !== null && RecommendationsGTM.length > 0) ?
                    <Container header="GoTo Market">
                      <ColumnLayout borders="horizontal" columns={1}>
                        {RecommendationsGTM.map((item, index) => (
                          <RecomDiv key={index.toString()}
                                    props={
                                      {
                                        what: item.what,
                                        When: item.When,
                                        rationale: item.rationale,
                                        outcome: item.outcome,
                                        awsinv: item.awsinv,
                                        nextsteps: item.nextsteps
                                      }
                                    }
                          />
                        ))}
                      </ColumnLayout>
                    </Container> : ""
                  }
                  <p/>
                  {(RecommendationsProcessOps !== null && RecommendationsProcessOps.length > 0) ?
                    <Container header="Process / Operations">
                      <ColumnLayout borders="horizontal" columns={1}>
                        {RecommendationsProcessOps.map((item, index) => (
                          <RecomDiv key={index.toString()}
                                    props={
                                      {
                                        what: item.what,
                                        When: item.When,
                                        rationale: item.rationale,
                                        outcome: item.outcome,
                                        awsinv: item.awsinv,
                                        nextsteps: item.nextsteps
                                      }
                                    }
                          />
                        ))}
                      </ColumnLayout>
                    </Container> : <div></div>
                  }
                  <p/>
                  {(RecommendationsStaff !== null && RecommendationsStaff.length > 0) ?
                    <Container header="Staff Proficiency">
                      <ColumnLayout borders="horizontal" columns={1}>
                        {RecommendationsStaff.map((item, index) => (
                          <RecomDiv key={index.toString()}
                                    props={
                                      {
                                        what: item.what,
                                        When: item.When,
                                        rationale: item.rationale,
                                        outcome: item.outcome,
                                        awsinv: item.awsinv,
                                        nextsteps: item.nextsteps
                                      }
                                    }
                          />
                        ))}
                      </ColumnLayout>
                    </Container> : <div></div>
                  }
                </div>
                <div id="Conclusion">
                  <h2> Conclusion </h2>
                  <hr/>
                  <NewlineText
                    text={Conclusion}
                  />
                </div>
                <hr style={{ color: '#000000', backgroundColor: '#000000', height: 5, borderColor: '#000000'}}/>
                <h1> Appendix </h1>
                <hr/>

                <SpaceBetween size="s">
                  {((PRStmtCurrent !== null && PRStmtCurrent.length > 0) || (PRStmtFuture !== null && PRStmtFuture.length>0)) ?
                    <Container id="PR Statements" header={<h3>PR Statements </h3>}>
                      <SpaceBetween size="s">
                        <Container header="Current">
                          <h3 style={{color: "#786eaa"}}>"{PRStmtCurrent}"</h3>
                        </Container>
                        <Container header="Future - 3 Years from now">
                          <h3 style={{color: "#669966"}}>"{PRStmtFuture}"</h3>
                        </Container>
                      </SpaceBetween>
                    </Container>
                    : <div></div>
                  }

                  {((SWOTStrengths !== null && SWOTStrengths.length > 0) || (SWOTWeaknesses !== null && SWOTWeaknesses.length>0) ||
                  (SWOTOpportunities !== null && SWOTOpportunities.length>0) || (SWOTThreats !== null && SWOTThreats.length>0))?
                  <Container id="SWOT Analysis" header={<h3>SWOT Analysis </h3>}>
                    <ColumnLayout columns={2}>
                      <div id="Strengths">
                        <h4>Strengths</h4>
                        <Box>
                          {SWOTStrengths.map((item, index) => (
                              <li key={index.toString()}>{item.value} </li>
                          ))}
                        </Box>
                      </div>
                      <div id="Weaknesses">
                        <h4>Weaknesses</h4>
                        <Box>
                          {SWOTWeaknesses.map((item, index) => (
                              <li key={index.toString()}>{item.value} </li>
                          ))}
                        </Box>
                      </div>
                      <div id="Weaknesses">
                        <h4>Opportunities</h4>
                        <Box>
                          {SWOTOpportunities.map((item, index) => (
                              <li key={index.toString()}>{item.value} </li>
                          ))}
                        </Box>
                      </div>
                      <div id="Threats">
                        <h4>Threats</h4>
                        <Box>
                          {SWOTThreats.map((item, index) => (
                              <li key={index.toString()}>{item.value} </li>
                          ))}
                        </Box>
                      </div>
                    </ColumnLayout>
                  </Container>
                    :<div></div>
                    }
                  {(UNICurrent !== null && UNICurrent.length > 0) ?
                    <Container id="Unicorn Super Powers" header={<h3>Unicorn Super Powers </h3>}>
                      <ColumnLayout columns={2}>
                        <Container header="Current Super Powers">
                          {UNICurrent.map((item, index) => (
                              <li key={index.toString()}>{item.value}</li>
                          ))}
                        </Container>
                        <Container header="Future Super Powers">
                          {UNIFuture.map((item, index) => (
                              <li key={index.toString()}>{item.value}</li>
                          ))}
                        </Container>
                      </ColumnLayout>
                    </Container> : <div></div>
                  }
                  {(Opportunities !== null && Opportunities.length > 0) ?
                    <Container id="Opportunities" header={<h3>Go-To-Market Opportunities </h3>}>
                      <ColumnLayout borders="horizontal" columns={1}>
                        {Opportunities.map((item, index) => (
                          <div key={index.toString()}>
                            <Grid
                              gridDefinition={[{colspan: 3}, {colspan: 9},
                                {colspan: 3}, {colspan: 9},
                                {colspan: 3}, {colspan: 9},
                                {colspan: 3}, {colspan: 9}]}
                            >
                              <div>Target Customer</div>
                              <div>{item.customer}</div>
                              <div>Problem / Opportunity</div>
                              <div>{item.problem}</div>
                              <div>Idea / Solution</div>
                              <div>{item.idea}</div>
                              <div>Next Steps</div>
                              <div>{item.nextsteps}</div>
                            </Grid>
                          </div>
                        ))}
                      </ColumnLayout>
                    </Container> : <div></div>
                  }
                  {((PRFAQCust !== null && PRFAQCust.length > 0) || (PRFAQSH !== null && PRFAQSH.length > 0)) ?
                    <Container id="PR/FAQ" header={<h3>PR/FAQ </h3>}>
                      <SpaceBetween size="s">
                        {(PRFAQCust !== null && PRFAQCust.length > 0) ?
                          <Container header="Customer FAQ">
                            <ColumnLayout borders="horizontal" columns={1}>
                              {PRFAQCust.map((item, index) => (
                                <div key={index.toString()}>
                                  <Grid
                                    gridDefinition={[{colspan: 2}, {colspan: 10},
                                      {colspan: 2}, {colspan: 10}]}
                                  >
                                    <div>Question:</div>
                                    <div>{item.question}</div>
                                    <div>Answer:</div>
                                    <div>{item.answer}</div>
                                  </Grid>
                                </div>
                              ))}
                            </ColumnLayout>
                          </Container> : <div></div>
                        }
                        {(PRFAQSH !== null && PRFAQSH.length > 0) ?
                          <Container header="Stakeholder FAQ">
                            <ColumnLayout borders="horizontal" columns={1}>
                              {PRFAQSH.map((item, index) => (
                                <div key={index.toString()}>
                                  <Grid
                                    gridDefinition={[{colspan: 2}, {colspan: 10},
                                      {colspan: 2}, {colspan: 10},
                                      {colspan: 2}, {colspan: 10},
                                      {colspan: 2}, {colspan: 10}]}
                                  >
                                    <div>Question:</div>
                                    <div>{item.question}</div>
                                    <div>Answer:</div>
                                    <div>{item.answer}</div>
                                  </Grid>
                                </div>
                              ))}
                            </ColumnLayout>
                          </Container> : <div></div>
                        }
                      </SpaceBetween>
                    </Container> : <div></div>
                  }
                </SpaceBetween>
              </ColumnLayout>
            </div>
          </Container>
        <div id="divBlankLines">
          <p/> <p/>
        </div>
      </div>
    )
  }
}

function RecomDiv(props) {
  return (
    <div >
      <Grid
        gridDefinition={[
          {colspan: 2}, {colspan: 10},
          {colspan: 2}, {colspan: 10},
          {colspan: 2}, {colspan: 10},
          {colspan: 2}, {colspan: 10},
          {colspan: 2}, {colspan: 10},
          {colspan: 2}, {colspan: 10}]}
      >
        <Box>What</Box>
        <Box>{props.props.what}</Box>
        <Box>When</Box>
        <Box>{props.props.When}</Box>
        <Box>Rationale</Box>
        <NewlineText2 text={props.props.rationale}/>
        <Box>Outcome(s)</Box>
        <NewlineText2 text={props.props.outcome}/>
        <Box>AWS Investment</Box>
        <NewlineText2 text={props.props.awsinv}/>
        <Box>Next Steps</Box>
        <NewlineText2 text={props.props.nextsteps}/>
        <p/>
      </Grid>
    </div>
  )
}

function NewlineText(props) {
  let newText = "";
  if (props.text !== null && props.text !== "" && props.text !== undefined) {
    const text = props.text;
    if (text.length > 0){
      newText = text.split('\n').map((str, index) => <div key={index.toString()}><Box>{str}</Box><p/></div>);
    }
  }
  return newText;
}

function NewlineText2(props) {
  let newText = "";
  if (props.text !== null && props.text !== "" && props.text !== undefined) {
    const text = props.text || "";
    newText = text.split('\n').map((str, index) => <div key={index.toString()}>{str}</div>);
  }
  return newText;
}

const mapStateToProps = (state) => {
  return {selectedSessionInfo: state};
}

export default connect(mapStateToProps, {
  fetchSession
})(WBWNarrative);