import React from "react";
import {SideNavigation} from "../../aws-ui-components";
import history from '../../history';
import {connect} from "react-redux";
import NavItems from "./NavIndex";
import SessionMgmt from "./SessionMgmt";
import PartnerProfile from "./PartnerProfile";

const SessionNav = (props) => {

  const [activeHref, setActiveHref] = React.useState(
    PartnerProfile
  );

  let sessionType = "";
  if (props.nav.sessionInfo.session !== undefined)
    sessionType = props.nav.sessionInfo.session.sessionType.value
  else
    sessionType = ""

  let navItemsFinal=[]
  if(NavItems[sessionType] !== undefined) {
     navItemsFinal = [...NavItems[sessionType], ...[{type: "divider"},
      {type: "link", text: "Session Management", href: SessionMgmt}]];
  }

  function onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      setActiveHref(ev.detail.href);
      if (ev.detail.href === "/")
        history.push(ev.detail.href);
      else
        props.navChange(ev.detail.href);
    }
  }

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{href: "/", text: "Home"}}
      onFollow={onFollowHandler}
      items={navItemsFinal}
    />
  );

}


const mapStateToProps = state => ({
  nav: state
});

export default connect(mapStateToProps)(SessionNav);