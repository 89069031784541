import React from "react";
import FieldGroup from "./FieldGroup.js";
import Field from "./Field";
import Radio from "./Radio";
import TestArea from "./TextArea";
import {Form, SpaceBetween} from "../../../../aws-ui-components";
import {connect} from "react-redux";
import {updateExcelUploadState, updatePreQ, updateSession} from "../../../../actions";

class DynamicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setState((this.props.store.sessionInfo.session.answers.preq !== undefined)?{...this.props.store.sessionInfo.session.answers.preq} : {})
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  fieldChanged = (fieldId, value, question, type, options) => {
    if (typeof(options) === 'object'){
      let ops = DynamicForm;
      for ( let x in options) {
        ops = options[x].value +","+ops;
      }
      this.setState( {[fieldId]: {question:question, answer:value}});
    } else
      this.setState( {[fieldId]: {question:question, answer:value}});
    this.props.updateEventChange(true);
  }

  handleSubmit = () => {
    this.props.updatePreQ(this).then( async () =>{
      this.props.saveToDB();
    });
  }

  render() {
   if (this.props.store !== undefined)
    if (this.props.store.excelUploadState !== undefined)
    if (this.props.store.excelUploadState.updateExcelUploadState !== undefined)
      if (this.props.store.excelUploadState.updateExcelUploadState) {
        this.state = (this.props.store.sessionInfo.session.answers.preq !== undefined) ? {...this.props.store.sessionInfo.session.answers.preq} : {};
        this.props.updateExcelUploadState({updateExcelUploadState: false})
      }

    return (
      <Form>
        <SpaceBetween size="l">
          {this.props.formData[0].fields.map((field, index) => {
            switch (field.component) {
              case "field_group":
                return (
                  <FieldGroup
                    component={field.component}
                    key={index.toString()}
                    field={field}
                    fieldBlur={this.handleSubmit}
                    fieldChanged={this.fieldChanged}
                    values={this.state}
                    disabled={!this.props.parent.edit}
                  />
                );
              case "radio":
                return (
                  <Radio
                    key={field._uid}
                    field={field}
                    fieldBlur={this.handleSubmit}
                    fieldChanged={this.fieldChanged}
                    disabled={!this.props.parent.edit}
                  />
                );
              case "testarea":
                return (
                  <TestArea
                    key={field._uid}
                    field={field}
                    fieldBlur={this.handleSubmit}
                    fieldChanged={this.fieldChanged}
                    value={this.state}
                    disabled={!this.props.parent.edit}
                  />
                );
              default:
                return (
                  <Field
                    key={field._uid}
                    field={field}
                    fieldBlur={this.handleSubmit}
                    fieldChanged={this.fieldChanged}
                    value={this.state}
                    disabled={!this.props.parent.edit}
                  />
                );
            }
          })}
        </SpaceBetween>
        <hr/>
      </Form>
    );
  }
}


const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  updatePreQ, updateSession, updateExcelUploadState
})(DynamicForm);
