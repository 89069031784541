import "../../styles/servicehomepage.css";
import '@amzn/awsui-global-styles/polaris.css';
import "../../styles/servicehomepage.css";

import {
  HelpPanel, Icon
} from "../../aws-ui-components";

const MainTools = () => {
  return (
    <HelpPanel
      header={<h2>PWMT Help</h2>}
      footer={
        <div>
          <p style={{color: "lightcoral"}}> version 1.0 (20220307)</p>
        </div>
      }
    >
      <div>
      <p>
        Partner WorkStreams Management Tool (PWMT) is used to conduct and persist PMSA WorkStreams related information.
      </p>
        <hr/>
        <h3>
          How you get Partner SPMS ID? <Icon name="external"/>
        </h3>
        <ul>
          <li>
            <a href="https://pipeline.builder.apn.a2z.com/#/search" target="_blank">
              Search for Partner in Pipeline Builder
            </a>
          </li>
          <li>
            <a href="https://discover.awspartner.com" target="_blank" rel="noreferrer">
              Search Partner in partner Discovery Portal
            </a>
          </li>
        </ul>

        <h3>
          Learn more <Icon name="external"/>
        </h3>
        <ul>
          <li>
            <a href="https://w.amazon.com/bin/view/AWS/Teams/PartnerSA/Partner_Management/Workstreams" target="_blank" rel="noreferrer">
              PMSA Work-Streams (Wiki)
            </a>
          </li>
          <li>
            <a href="https://aws-crm.my.salesforce.com" target="_blank" rel="noreferrer">
              AWS SFDC
            </a>
          </li>
          <li>
            <a href="http://apn-portal.my.salesforce.com" target="_blank" rel="noreferrer">
              APN SFDC
            </a>
          </li>
        </ul>
      </div>
    </HelpPanel>
  );
}

export default MainTools;

