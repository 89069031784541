import React from "react";
import "../../styles/servicehomepage.css";
import {
    AppLayout
} from "../../aws-ui-components";

import HomeNav from "./HomeNav.js";
import InsightsContent from "./InsightsContent.js";
import {connect} from "react-redux";
import {getUser} from "../../actions";
import MainTools from "./MainTools";

class Insights extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.props.getUser(this.state);
    }

    handleChange(event) {}

    render() {
        return (
            <AppLayout
                disableContentPaddings={true}
                navigation={<HomeNav props={this.state} />}
                content={<InsightsContent props={this.state} />}
                navigationOpen={true}
                toolsHide={false}
                tools = {<MainTools/>}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {state};
}

export default connect(mapStateToProps, {
    getUser
})(Insights);
