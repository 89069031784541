import React, { useEffect, useState, Component}  from "react";
import {connect} from 'react-redux';
import "../../styles/servicehomepage.css";
import '@amzn/awsui-global-styles/polaris.css';
import "../../styles/servicehomepage.css";
import MySessionsTable from "./MySessionsTable";


import {
  AppLayout
} from "../../aws-ui-components";

import HomeNav from "./HomeNav";
import {fetchMySessions} from "../../actions";
import MainTools from "./MainTools";

class MySessions extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
   this.props.fetchMySessions();
  }

  render() {
    return (
      <AppLayout
        disableContentPaddings={true}s
        navigation={<HomeNav />}
        content={ <MySessionsTable /> }
        contentType="default"
        navigationOpen={true}
        tools={<MainTools/>}
        toolsHide={false}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sessions: state.sessionInfo.sessions,
    auth: state.auth
  };
}

export default connect(mapStateToProps, {
  fetchMySessions
})(MySessions);


