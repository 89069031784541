import React, {useEffect, useState} from "react";
import {Button, ColumnLayout, Grid, Modal, SpaceBetween, Spinner, Textarea, Box} from "../../../../aws-ui-components";
import "../../../../styles/servicehomepage.css"


const DField = ({props, name, handleSave, updateEventChange, textRows, edit}) => {
  const [fields, setFields] = useState([{value: null}]);
  const [change, setChange] = useState([{value: null}]);
  const [delModal, setDelModal] = useState(false);
  const [delIDX, setDelIDX] = useState(null);
  const [txtWidth, setTxtWidth] = useState( 450);

  //console.log("DField");
  const numRows = textRows || 2;

  useEffect(() => {
    if (props !== undefined) {
      setFields(props);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  function handleResize(){
    if (document.getElementById('textarea') !== null) {
      setTxtWidth(document.getElementById('textarea').clientWidth);
    }
  }

  function displayTextWidth(text, font) {
    let canvas = displayTextWidth.canvas || (displayTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    // console.log(metrics.width +" / "+document.getElementById('textarea').clientWidth);
    return metrics.width;
  }

  function handleChange(i, event) {
    const values = fields;
    values[i].value = event;
    setFields([...values]);
    updateEventChange(true);
  }

  function handleAdd(e) {
    e.preventDefault();
    const values = fields;
    values.push({value: null});
    setFields([...values]);
    updateEventChange(true);
  }

  function handleRemove(i, e) {
    setDelIDX(i);
    setDelModal(true);
    updateEventChange(true);
  }

  function handleRemoveItem() {
    setDelModal(false);
    const values = fields;
    values.splice(delIDX, 1);
    setFields([...values]);
    updateEventChange(true);
  }

  return (
    <div className="App" id="Div">
      <SpaceBetween direction="vertical" size="xxs" >
        {
          fields.map((field, idx) => {
            return (
              <div key={`${field}-${idx}`}>
                <Modal
                  visible={delModal}
                  onDismiss={() => setDelModal(false)}
                  size="small"
                  header={<div>Delete: Are you sure?</div>}
                >
                  <Box textAlign="center">
                    <Button
                      id={`${field}-${idx}`}
                      variant="primary"
                      icon="close"
                      onClick={(detail) => {
                        handleRemoveItem();
                        handleSave(name, fields)
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Modal>

                <ColumnLayout columns={1}>
                  <Grid
                    gridDefinition={[{colspan: 11, offset: {default: 0}}, {colspan: 1, offset: {default: 0}}]}
                  >
                    <div id="textarea">
                    <Textarea
                      //disabled={!edit}
                      readOnly = {!edit}
                      onChange={({detail}) => {
                        handleChange(idx, detail.value);
                      }}
                      value={field.value}
                      onBlur={
                        (detail) => {
                          (!change) || detail.preventDefault();
                          (!change) || handleSave(name, fields);
                        }
                      }
                      rows={(field.value !== null)? (1.4*displayTextWidth(field.value)/(txtWidth) + field.value.split("\n").length): 1}
                      //rows={(field.value !== null)? (field.value.length/70 + field.value.split("\n").length): 1}
                      placeholder="Enter Details..."
                    />
                    </div>

                    {(edit === true) ?
                      <Button
                        disabled={!edit}
                        variant="icon"
                        iconName="close"
                        onClick={(detail) => {
                          handleRemove(idx, detail);
                        }}
                      /> : <div></div>
                    }


                  </Grid>
                </ColumnLayout>
              </div>
            );
          })}

        {(edit === true) ?
          <Button disabled={!edit}
                  iconAlign="right"
                  iconName="add-plus"
                  variant="primary"
                  onClick={(detail) => {
                    handleAdd(detail);
                    handleSave(name, fields)
                  }}
          >Add</Button> : <div></div>
        }

      </SpaceBetween>
    </div>
  );
}

export default DField;
