import React from 'react';
import SessionHeader from "../SessionHeader";
import {Box, Table, Header} from "../../../aws-ui-components";
import {connect} from "react-redux";
import {fetchSession} from "../../../actions";

class WBWRoadmap extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      items: []
    }
    this.props.turnOffFlashBar();
  }

  sortByProperty(property){
    return function(a,b){
      if(a[property] > b[property])
        return 1;
      else if(a[property] < b[property])
        return -1;
      return 0;
    }
  }

  componentDidMount() {
    if( (this.props.selectedSessionInfo) !== undefined) {
      if (this.props.selectedSessionInfo.sessionInfo !== undefined) {
        if (this.props.selectedSessionInfo.sessionInfo.session !== undefined) {
          if (this.props.selectedSessionInfo.sessionInfo.session.answers !== undefined) {
            if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations !== undefined) {
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.OrgAlign !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.OrgAlign);
              }
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.GTM !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.GTM);
              }
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.ProcessOps !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.ProcessOps);
              }
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.SolDev !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.SolDev);
              }
              if (this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.StaffProf !== undefined) {
                this.state.items.push(...this.props.selectedSessionInfo.sessionInfo.session.answers.recommendations.StaffProf);
              }
            }
          }
        }
      }
    }
    let x =[];
    this.state.items.map((item) => {
      if (item.When !== null)
        if(item.When.length > 5 ) {
          x.push(item);}
    })
    x.sort(this.sortByProperty("When"));
    this.setState({items: x});
  }

  render() {
    return(
      <div>
        <Box margin="l">
            <div>
              <Table
                columnDefinitions={[
                  {
                    id: "When",
                    header: "When",
                    cell: item => item.When || "-",
                    width: 135
                  },
                  {
                    id: "what",
                    header: "Activity / Milestone",
                    cell: item => item.what || "-",
                    width: 300
                  },
                  {
                    id: "Outcome",
                    header: "Outcome",
                    cell: item => item.outcome || "-",
                    width: 300
                  },
                  {
                    id: "awsinv",
                    header: "AWS Investment",
                    cell: item => item.awsinv || "-"
                  },
                ]}
                wrapLines
                items={this.state.items}
                loadingText="Loading resources"
                empty={
                  <Box textAlign="center" color="inherit">
                    <b>No items to display. Please enter items in Recommendations.</b>
                  </Box>
                }
                header={ <Header>Roadmap of Activities</Header> }
              />
            </div>
        </Box>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {selectedSessionInfo: state};
}

export default connect(mapStateToProps, {
  fetchSession
})(WBWRoadmap);