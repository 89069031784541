import React from 'react';
import {Box, Tabs, Form, ColumnLayout, Container, Header, Textarea, Alert} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession, updateUNI, updateSession} from "../../../actions";
import DField from "../resources/form/DField";
import {Toggle} from "@amzn/awsui-components-react";
import SessionHold from "../resources/form/SessionHold";

class WBWUni extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      uni: {
        currentUniSPs: [],
        futureUniSPs: [],
        uniNotes: ""
      },
      edit: false
    }

    if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
      const vals = this.props.store.sessionInfo.session.answers;
      let uni = [];
      if (vals !== undefined && vals !== null) {
        if (vals.uni !== undefined &&  vals.uni !== null) {
          uni = vals.uni;
          if (uni.currentUniSPs !== undefined && uni.currentUniSPs !== null)
            this.state.uni.currentUniSPs =  uni.currentUniSPs
          if (uni.futureUniSPs !== undefined && uni.futureUniSPs !== null)
            this.state.uni.futureUniSPs =  uni.futureUniSPs
          if (uni.currentquote !== undefined && uni.currentquote !== null)
            this.state.uni.currentquote = uni.currentquote
          if (uni.futurequote !== undefined && uni.futurequote !== null)
            this.state.uni.futurequote = uni.futurequote
          if (uni.uniNotes !== undefined && uni.uniNotes !== null)
            this.state.uni.uniNotes = uni.uniNotes
        }
      }
    }
  }

  handleSave = (name, values) => {
    switch (name) {
      case "currentUniSPs":
        this.state.uni.currentUniSPs = [...values];
        break;
      case "futureUniSPs":
        this.state.uni.futureUniSPs = [...values];
        break;
      default:
    }
    this.props.updateUNI(this).then(async () =>{
      this.props.saveToDB();
    });
  }

  handleTextSave = () => {
    this.props.updateUNI(this).then(async () => {
      this.props.saveToDB();
    });
  }

  handleTextChange = (name, value) => {
    this.setState({ uni: {[name]: value }});
    this.props.updateEventChange(true);
    this.props.updateUNI(this)
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {
    return (
      <div>
        <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>
        <Tabs
          tabs={[
            {
              label: "Unicorn Super Powers",
              id: "Unicorn Super Powers",
              content: <div>
                <Box margin="l">
                  <div>
                    <ColumnLayout columns={2}>
                      <Container
                        header={
                          <Header variant="h2"
                                  description="">
                            Current Unicorn Super Powers
                          </Header>
                        }
                      >
                        <DField props={this.state.uni.currentUniSPs} name="currentUniSPs" handleSave={this.handleSave} updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                      </Container>

                      <Container
                        header={
                          <Header variant="h2"
                                  description="">
                            Future Unicorn Super Powers
                          </Header>
                        }
                      >
                        <DField props={this.state.uni.futureUniSPs} name="futureUniSPs" handleSave={this.handleSave} updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                      </Container>

                    </ColumnLayout>
                  </div>
                </Box>
              </div>
            },
            {
              label: "Notes",
              id: "Notes",
              content:   <Container>
                <Textarea
                  disabled={!this.state.edit}
                  autoComplete={false}
                  name="prstmtNotes"
                  onChange={({ detail }) => this.handleTextChange("uniNotes", detail.value)}
                  onBlur={(detail) => {detail.preventDefault(); this.handleTextSave()}}
                  value={this.state.uni.uniNotes}
                  rows = {(this.state.uni.uniNotes !== null)? (this.state.uni.uniNotes.length/160 + this.state.uni.uniNotes.split("\n").length): 1}
                  placeholder="Enter your notes here..."
                />
              </Container>
            }
            ]}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateUNI, updateSession
})(WBWUni);