import React from "react";
import {Button} from "../../../../aws-ui-components";
import history from "../../../../history"
import {connect} from "react-redux";
import {AddSelected} from "../../../../actions";

class Linker extends React.Component{
  constructor(props) {
    super(props);
    this.state = {}
  }

  route = () =>{
    this.props.AddSelected(this.props.id);
    history.push(this.props.link);
  }
// <a href="#">{this.props.name}</a>
  render() {
    return(
      <div onClick={()=>this.route()}>
        <Button variant="link" iconName="edit" color>{this.props.name}</Button>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  AddSelected
})(Linker);