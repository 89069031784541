import React from "react";
import { Link } from "../../../aws-ui-components";
import Linker from "./utils/Linker";

export const COLUMN_DEFINITIONS = [
  {
    id: "partnerName",
    header: "Partner Name",
    cell: item => (
      <Linker link={"/partner?"+item.spmsid} name={item.partnerName} id={item.spmsid}/>
    ),
    minWidth: "120px",
    sortingField: "partnerName"
  },
  {
    id: "spmsid",
    header: "Partner ID",
    cell: item => item.spmsid,
    minWidth: "40px",
  },
  {
    id: "highPotential",
    header: "High Potential",
    cell: item => (item.highPotential)?"Yes":"No",
    minWidth: "40px",
  }
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: "Partners Attributes",
    options: [
      { id: "partnerName", label: "Partner Name", editable: false },
      { id: "spmsid", label: "partner ID", editable: false },
      { id: "highPotential", label: "High Potential", editable: true }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: "10 sessions" },
  { value: 30, label: "30 sessions" },
  { value: 50, label: "50 sessions" }
];

export const CUSTOM_PREFERENCE_OPTIONS = [
  { value: "table", label: "Table" },
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    "partnerName",
    "spmsid",
  ],
  wraplines: false,
};


export const DEFAULT_PREFERENCES_SEARCH = {
  pageSize: 10,
  visibleContent: [
    "partnerID",
    "partnerName",
  ],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const dateConvert = (value) =>{
  const d = new Date(value);
  return d.toDateString();
}

