/************************************************************************
 DISCLAIMER

 This is just a playground package. It does not comply with best practices
 of using AWS-UI components. For production code, follow the integration
 guidelines:

 https://polaris.a2z.com/develop/integration/react/
 ************************************************************************/
import React, { useEffect, useState } from "react";
import {connect} from 'react-redux';
import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  DEFAULT_PREFERENCES,
  PAGE_SELECTOR_OPTIONS
} from "./resources/my-sessions-table-config.jsx";
import {
  Box,
  Button,
  CollectionPreferences,
  FormField,
  Header,
  Pagination,
  RadioGroup,
  SpaceBetween,
  Table,
  TextFilter,
  Modal
} from "../../aws-ui-components";

import { useCollection } from "@awsui/collection-hooks";
import {fetchMySessions} from "../../actions";
import PWMTHeader from "./MainHeader";
import CreateSession from "./CreateSession";

const MySessionsTable = (props) => {

  const [sessions, setSessions] = useState([]);
  const [selectedSessions, setSelectedSessions] = useState([]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(true);
  const [CSModalVisible, setCSModalVisible] = useState(false);

  const {
    items,
    actions,
    collectionProps,
    filterProps,
    paginationProps,
    filteredItemsCount
  } = useCollection(sessions, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    filtering: {
      noMatch: (
        <Box textAlign="center" color="inherit">
          <b>No matches</b>
          <Box color="inherit" margin={{ top: "xxs", bottom: "s" }}>
            No results match your query
          </Box>
          <Button onClick={() => actions.setFiltering("")}>Clear filter</Button>
        </Box>
      )
    }
  });

  useEffect( async () =>  {
      if (props.sessions !== undefined) {
        await setSessions(props.sessions);
        await setLoading(false);
      }
  });

  function onClickHandler(){
    setCSModalVisible(true);
  }

  // Keeps track of how many sessions are selected
  function headerCounter(selectedSessions, sessions) {
    return selectedSessions.length
      ? `(${selectedSessions.length} of ${sessions.length})`
      : `(${sessions.length})`;
  }

  function filterCounter(count) {
    return `${count} ${count === 1 ? "match" : "matches"}`;
  }

  function dismissCSModal(){
    setCSModalVisible(false);
  }

  return (
    <div>
      <PWMTHeader />
      <Modal
        visible={CSModalVisible}
        size="large"
        header ={<div > Add Session </div>}
        onDismiss={dismissCSModal}
      >
        <CreateSession />
      </Modal>

      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        visibleColumns={preferences.visibleContent}
        stickyHeader={true}
        items={items}
        loading={loading}
        loadingText="Loading sessions"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No resources to display.
            </Box>
            <Button onClick={onClickHandler} > Create Session </Button>
          </Box>
        }
        header={
            <Header
            variant="h2"
            counter={headerCounter(selectedSessions, sessions)}
            actions={
              <SpaceBetween direction="horizontal" size="s">
               <Button iconName="add-plus" onClick={onClickHandler} variant="primary"> Create a Session </Button>
              </SpaceBetween>
            }
            >
            My Sessions
            </Header>
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: "Page size",
              options: PAGE_SELECTOR_OPTIONS
            }}
            visibleContentPreference={{
              title: "Select visible columns",
              options: CONTENT_SELECTOR_OPTIONS
            }}
            wrapLinesPreference={{
              label: "Wrap lines",
              description: "Check to see all the text and wrap the lines"
            }}
            customPreference={(value, setValue) => (
              <FormField stretch={true} label="View as">
                <RadioGroup
                  value={value}
                  onChange={({ detail }) => setValue(detail.value)}
                  items={CUSTOM_PREFERENCE_OPTIONS}
                />
              </FormField>
            )}
          />
        }
        wrapLines={preferences.wrapLines}
        selectedItems={selectedSessions}
        onSelectionChange={({ detail }) =>
          setSelectedSessions(detail.selectedItems)
        }
        //selectionType="multi"
        pagination={<Pagination {...paginationProps} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={filterCounter(filteredItemsCount)}
            filteringPlaceholder="Search Sessions"
          />
        }
      />
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    sessions: state.sessionInfo.sessions
  };
}

export default connect(mapStateToProps, {
  fetchMySessions
})(MySessionsTable);
