import React from 'react';
import {Tabs, Box, Form, Container, Grid, Header, SpaceBetween} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession, updateRecomm, updateSession} from "../../../actions";
import DRecomForm from '../resources/form/DRecomForm';
import {Alert, Toggle} from "@amzn/awsui-components-react";
import SessionHold from "../resources/form/SessionHold";

class WBWRecommendations extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      recommendations: {
        OrgAlign: [],
        SolDev: [],
        GTM: [],
        ProcessOps: [],
        StaffProf: [],
      },
      edit: false
    }

    if (this.props.store.sessionInfo)
    if (this.props.store.sessionInfo.session)
    if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
      const vals = this.props.store.sessionInfo.session.answers;
      let recommendations = [];
      if (vals !== undefined && vals !== null) {
        if (vals.recommendations !== undefined &&  vals.recommendations !== null) {
          recommendations = vals.recommendations;
          if (recommendations.OrgAlign !== undefined && recommendations.OrgAlign !== null)
            this.state.recommendations.OrgAlign =  recommendations.OrgAlign
          if (recommendations.SolDev !== undefined && recommendations.SolDev !== null)
            this.state.recommendations.SolDev =  recommendations.SolDev
          if (recommendations.GTM !== undefined && recommendations.GTM !== null)
            this.state.recommendations.GTM =  recommendations.GTM
          if (recommendations.ProcessOps !== undefined && recommendations.ProcessOps !== null)
            this.state.recommendations.ProcessOps =  recommendations.ProcessOps
          if (recommendations.StaffProf !== undefined && recommendations.StaffProf !== null)
            this.state.recommendations.StaffProf =  recommendations.StaffProf
        }
      }
    }

  }

  handleSave = (name, values) => {
    switch (name) {
      case "OrgAlign":
        this.state.recommendations.OrgAlign = [...values];
        break;
      case "SolDev":
        this.state.recommendations.SolDev = [...values];
        break;
      case "GTM":
        this.state.recommendations.GTM = [...values];
        break;
      case "ProcessOps":
        this.state.recommendations.ProcessOps = [...values];
        break;
      case "StaffProf":
        this.state.recommendations.StaffProf = [...values];
        break;
    }
    this.props.updateRecomm(this).then(async()=>{
      this.props.saveToDB();
    });
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {
    return(
      <div>
        <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>
              <div>
                <Tabs
                  tabs={[
                    {
                      label: " Organizational Alignment",
                      id: " Organizational Alignment",
                      content: <div>
                          <Box margin="l">
                            <DRecomForm props={this.state.recommendations.OrgAlign} name="OrgAlign" handleSave={this.handleSave} updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                          </Box>
                        </div>
                    },
                    {
                      label: "Solution or Practice Development",
                      id: "Solution or Practice Development",
                      content: <div>
                        <Box margin="l">
                          <DRecomForm props={this.state.recommendations.SolDev} name="SolDev" handleSave={this.handleSave} updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                        </Box>
                      </div>
                    },
                    {
                      label: "Go to Market",
                      id: "Go to Market",
                      content:  <div>
                        <Box margin="l">
                          <DRecomForm props={this.state.recommendations.GTM} name="GTM" handleSave={this.handleSave} updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                        </Box>
                      </div>
                    },
                    {
                      label: "Process and Operations",
                      id: "Process and Operations",
                      content:  <div>
                        <Box margin="l">
                          <DRecomForm props={this.state.recommendations.ProcessOps} name="ProcessOps" handleSave={this.handleSave} updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                        </Box>
                      </div>

                    },
                    {
                      label: "Staff Proficiency",
                      id: "Staff Proficiency",
                      content:  <div>
                        <Box margin="l">
                          <DRecomForm props={this.state.recommendations.StaffProf} name="StaffProf" handleSave={this.handleSave} updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                        </Box>
                      </div>
                    }
                  ]}
                />
              </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateRecomm, updateSession
})(WBWRecommendations);