import React from 'react';
import {Tabs, Box, Container, Grid, Header, Textarea} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession, updateStr, updateSession} from "../../../actions";
import {Alert, Toggle} from "@amzn/awsui-components-react";
import DPracticeAreas from "./resources/DPracticeAreas";

class PartnerCapabilities extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      partnerResearch: {
        pa: []
      },
      edit:false
    }

  }

  handleTextChange = (name, value) => {
  }
  handleTextSave = (name, value) => {
  }

  componentWillUnmount() {
    //this.props.saveToDB();
  }

  handleSave = () =>{}

  updateEventChange =()=>{}

  render() {
    return(
      <div>
        <Box float="right" margin="xs">
          <Toggle
            onChange={({detail}) =>
              this.setState({edit: detail.checked})
            }
            checked={this.state.edit}
          >
            Edit
          </Toggle>
        </Box>
        <Tabs
          tabs={[
            {
              label: "Practices Areas",
              id: "Practices Areas",
              content:   <div>
                <Box margin="l">
                  <Container
                  >

                    <DPracticeAreas props={this.state.partnerResearch.pa} name="PA" handleSave={this.handleSave}
                                    updateEventChange={this.updateEventChange} edit={this.state.edit}/>

                  </Container>
                </Box>
              </div>
            },
            {
              label: "Revenue Streams",
              id: "Revenue Stream",
              content:   <div>
                <Box margin="l">
                  <Container
                  >

                  </Container>
                </Box>
              </div>
            },
            {
              label: "Key Verticals",
              id: "Key Verticals",
              content:   <div>
                <Box margin="l">
                  <Container>

                  </Container>
                </Box>
              </div>
            },
            {
              label: "Key AWS Segments",
              id: "Key AWS Segments",
              content:   <div>
                <Box margin="l">
                  <Container>

                  </Container>
                </Box>
              </div>
            },
            {
              label: "Org. Capabilities",
              id: "Capabilities",
              content:   <div>
                <Box margin="l">
                  <Container
                  >

                  </Container>
                </Box>
              </div>
            },
            ]}
          />
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateStr, updateSession
})(PartnerCapabilities);