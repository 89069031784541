import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Textarea,
  Container,
  Grid,
  SpaceBetween, Input,
  Form, Modal
} from "../../../../aws-ui-components";
import "../../../../styles/servicehomepage.css"

const DOppForm = ({props, name, handleSave, updateEventChange, edit}) => {
  const [fields, setFields] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [delIDX, setDelIDX] = useState(null);

  useEffect(() => {
    if (props !== undefined){
      setFields(props);
    }
  }, []);

  function handleChange(i,name, event) {
    const values = fields;
    values[i] = {...values[i], [name]: event} ;
    setFields([...values]);
    updateEventChange(true);
  }

  function handleAdd(e) {
    e.preventDefault();
    const values = fields;
    values.push({ customer: null, problem: null, idea: null, nextsteps:null });
    setFields([...values]);
    updateEventChange(true);
  }

  function handleRemove(i, e) {
    setDelIDX(i);
    setDelModal(true);
    updateEventChange(true);
  }

  function handleRemoveItem() {
    setDelModal(false);
    const values = fields;
    values.splice(delIDX, 1);
    setFields([...values]);
    updateEventChange(true);
  }

  return (
    <div className="App">
        <SpaceBetween direction="vertical" size="xxs">
          {
            fields.map((field, idx) => {
            return (
              <div key={`${field}-${idx}`}>
                <Modal
                  visible={delModal}
                  onDismiss={() => setDelModal(false)}
                  size="small"
                  header={<div>Delete: Are you sure?</div>}
                >
                  <Box textAlign="center">
                    <Button
                      id={`${field}-${idx}`}
                      variant="primary"
                      icon="close"
                      onClick={(detail) => {
                        handleRemoveItem();
                        handleSave(name, fields)
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Modal>

                <Grid
                  gridDefinition={[{ colspan: 11, offset: { default: 0 } }, { colspan: 1, offset: { default: 0 }  }]}
                >
                  <div>
                    <Container>
                      <label>
                        Customer Type/Profile
                        <Input
                          //disabled={!edit}
                          readOnly={!edit}
                          onChange={({ detail }) => handleChange(idx, "customer", detail.value)}
                          value = {field.customer}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {1}
                          placeholder="Enter Details..."
                        />
                      </label>

                      <label>
                        Problem / Opportunity
                        <Textarea
                          //disabled={!edit}
                          readOnly={!edit}
                          onChange={({ detail }) => handleChange(idx, "problem", detail.value)}
                          value = {field.problem}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {(field.problem !== null)? (field.problem.length/160 + field.problem.split("\n").length): 1}
                          placeholder="Enter details..."
                        />
                      </label>
                      <label>
                        Idea / Potential Solution
                        <Textarea
                          //disabled={!edit}
                          readOnly={!edit}
                          onChange={({ detail }) => handleChange(idx, "idea", detail.value)}
                          value = {field.idea}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {(field.idea !== null)? (field.idea.length/160 + field.idea.split("\n").length): 1}
                          placeholder="Enter details..."
                        />
                      </label>
                      <label>
                        Next Steps
                        <Textarea
                          //disabled={!edit}
                          readOnly={!edit}
                          onChange={({ detail }) => handleChange(idx, "nextsteps", detail.value)}
                          value = {field.nextsteps}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {(field.nextsteps !== null)? (field.nextsteps.length/160 + field.nextsteps.split("\n").length): 1}
                          placeholder="Enter details..."
                        />
                      </label>
                    </Container>
                  </div>
                  {(edit === true) ?
                  <Button
                    id={`${field}-${idx}`}
                    variant="icon"
                    iconName="close"
                    onClick={(detail) => {handleRemove(idx, detail); handleSave(name, fields)}}
                  />:<div></div>
                  }
                </Grid>
              </div>
            );
          })}
          {(edit === true) ?
            <Button iconAlign="right"
                    iconName="add-plus"
                    variant="primary"
                    onClick={(detail) => {
                      handleAdd(detail);
                      handleSave(name, fields)
                    }}
            >Add</Button> : <div></div>
          }
        </SpaceBetween>
    </div>
  );
}

export default DOppForm;
