import React from 'react';
import {Box, ColumnLayout, Container, Header, Tabs, Textarea} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession, updateSession, updatePRS} from "../../../actions";
import DField from "../resources/form/DField";
import {Alert, Toggle} from "@amzn/awsui-components-react";
import SessionHold from "../resources/form/SessionHold";

class WBWPRFAQ extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      prs: {
        currentprstmt: [],
        futureprstmt: [],
        currentquote: "",
        futurequote: "",
        prstmtNotes: ""
      },
      edit: false
    }

    if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
      const vals = this.props.store.sessionInfo.session.answers;
      let prs = [];
      if (vals !== undefined && vals !== null) {
        if (vals.prs !== undefined && vals.prs !== null) {
          prs = vals.prs;
          if (prs.currentprstmt !== undefined && prs.currentprstmt !== null)
            this.state.prs.currentprstmt = prs.currentprstmt
          if (prs.futureprstmt !== undefined && prs.futureprstmt !== null)
            this.state.prs.futureprstmt = prs.futureprstmt
          if (prs.currentquote !== undefined && prs.currentquote !== null)
            this.state.prs.currentquote = prs.currentquote
          if (prs.futurequote !== undefined && prs.futurequote !== null)
            this.state.prs.futurequote = prs.futurequote
          if (prs.prstmtNotes !== undefined && prs.prstmtNotes !== null)
            this.state.prs.prstmtNotes = prs.prstmtNotes
        }
      }
    }
  }

  handleSave = (name, values) => {
    switch (name) {
      case "currentprstmt":
        this.state.prs.currentprstmt = [...values];
        break;
      case "futureprstmt":
        this.state.prs.futureprstmt = [...values];
        break;
      case "currentquote":
        this.state.prs.currentquote = [...values];
        break;
      case "futurequote":
        this.state.prs.futurequote = values;
        break;
      case "prstmtNotes":
        this.state.prs.prstmtNotes = values;
        break;
    }
    this.props.updatePRS(this).then(async()=>{
      await this.props.saveToDB();
    });
  }


  handleTextChange = (name, value) => {
    this.setState({prs: {...this.state.prs, [name]: value}});
    this.props.updateEventChange(true);
  }

  handleTextSave = () => {
    this.props.updatePRS(this).then(async()=>{
      await this.props.saveToDB();
    });
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {
    return (
      <div>
        <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>
        <Tabs
          tabs={[
            {
              label: "PR Statements",
              id: "PR Statements",
              content: <div>
                <Box margin="l">
                  <ColumnLayout columns={2}>
                    <Container
                      header={
                        <Header variant="h2"
                                description="Points you'd provide to a hypothetical Andy Jassy for him to talk about your company in an event like Re:Invent Tomorrow">
                          Current State PR Statement
                        </Header>
                      }
                    >
                      <DField props={this.state.prs.currentprstmt} name="currentprstmt" handleSave={this.handleSave}
                              updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                    </Container>

                    <Container
                      header={
                        <Header variant="h2"
                                description="Points you'd provide to a hypothetical Andy Jassy for him to talk about your company in an event like Re:Invent Three years from now">
                          Future State PR Statement
                        </Header>
                      }
                    >
                      <DField props={this.state.prs.futureprstmt} name="futureprstmt" handleSave={this.handleSave}
                              updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                    </Container>

                    <Container
                      header={
                        <Header variant="h2" description="Current PR Quote">
                          Current Quote
                        </Header>
                      }
                    >
                      <Textarea
                        disabled={!this.state.edit}
                        autoComplete={false}
                        name="currentquote"
                        onChange={({detail}) => this.handleTextChange("currentquote", detail.value)}
                        onBlur={(detail) => {
                          detail.preventDefault();
                          this.handleTextSave()
                        }}
                        value={this.state.prs.currentquote}
                        placeholder="Enter your Quote..."
                        rows={8}
                      />

                    </Container>

                    <Container
                      header={
                        <Header variant="h2" description="Future PR Quote">
                          Future Quote
                        </Header>
                      }
                    >
                      <Textarea
                        disabled={!this.state.edit}
                        autoComplete={false}
                        name="futurequote"
                        onChange={({detail}) => this.handleTextChange("futurequote", detail.value)}
                        onBlur={(detail) => {
                          detail.preventDefault();
                          this.handleTextSave()
                        }}
                        value={this.state.prs.futurequote}
                        placeholder="Enter your Quote..."
                        rows={8}
                      />
                    </Container>
                  </ColumnLayout>
                </Box>
              </div>
            },
            {
              label: "Notes",
              id: "Notes",
              content: <Container>
                <Textarea
                  disabled={!this.state.edit}
                  autoComplete={false}
                  name="prstmtNotes"
                  onChange={({detail}) => this.handleTextChange("prstmtNotes", detail.value)}
                  onBlur={(detail) => {
                    detail.preventDefault();
                    this.handleTextSave()
                  }}
                  value={this.state.prs.prstmtNotes}
                  rows={(this.state.prs.prstmtNotes !== null)? (this.state.prs.prstmtNotes.length/160 + this.state.prs.prstmtNotes.split("\n").length): 1}
                  placeholder="Enter your notes here..."
                />
              </Container>
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateSession, updatePRS
})(WBWPRFAQ);