import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  DatePicker,
  Form,
  FormField,
  Grid,
  Input,
  Modal,
  Select,
  SpaceBetween
} from "../../../../aws-ui-components";
import "../../../../styles/servicehomepage.css"
import {Header, Table} from "@amzn/awsui-components-react";

const DAttendees = ({props, name, companies, events, handleSave, updateEventChange, edit}) => {
  const [fields, setFields] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [delIDX, setDelIDX] = useState(null);
  const [editIDX, seteditIDX] = useState(null);
  const [editInProgress, setEditInProgress] = useState(false);
  const [attendee, setAttendee] = useState({
    name: "",
    designation: "",
    email: "",
    company: {label: "", value: ""},
    eventName: {label: "", value: ""},
    attDate: null
  });
  const [modalAddAttendee, setModalAddAttendee] = useState(false);
  const [selectedItems, setSelectedItems] = useState();

  useEffect(() => {
    if (props !== undefined) {
      setFields(props);
    }
  }, []);

  function handleChange(name, value) {
    setAttendee({...attendee, [name]: value});
  }

  function handleNewAddAttendee() {
    setAttendee({
      name: "",
      designation: "",
      email: "",
      company: {label: "", value: ""},
      eventName: {label: "", value: ""},
      attDate: null
    });
    setModalAddAttendee(true);
  }

  function handleSaveAttendee(e) {
    const values = fields;
    if (editInProgress === false) {
      values.push(attendee);
      setFields([...values]);
      handleSave(name, fields);
    } else {
      console.log(values);
      values[editIDX] = attendee;
      console.log(values);
      setFields([...values]);
      handleSave(name, fields);
      setEditInProgress(false)
    }
  }

  function handleRemove(i) {
    setDelIDX(i);
    setDelModal(true);
    updateEventChange(true);
  }

  function handleEdit(i) {
    seteditIDX(i);
    setAttendee(fields[[i]]);
    setEditInProgress(true);
    setModalAddAttendee(true);
    updateEventChange(true);
  }


  function handleRemoveItem() {
    setDelModal(false);
    const values = fields;
    values.splice(delIDX, 1);
    setFields([...values]);
    updateEventChange(true);
  }

  return (
    <div className="App">
      <Modal
        visible={modalAddAttendee}
        onDismiss={() => setModalAddAttendee(false)}
        closeAriaLabel="Close modal"
        size="medium"
        header="Enter Attendee Detail"
      >
        <Form
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="primary"
                      onClick={(e) => {
                        handleSaveAttendee(e);
                        setModalAddAttendee(false);
                      }}
              >Submit</Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Name">
              <Input placeholder="Name"
                     onChange={({detail}) => handleChange("name", detail.value)}
                     value={attendee.name}
                     readOnly = {!edit}
              />
            </FormField>
            <FormField label="Designation">
              <Input placeholder="Designation"
                     onChange={({detail}) => handleChange("designation", detail.value)}
                     value={attendee.designation}
                     readOnly = {!edit}
              />
            </FormField>
            <FormField label="Email">
              <Input placeholder="Email"
                     onChange={({detail}) => handleChange("email", detail.value)}
                     value={attendee.email}
                     readOnly = {!edit}
              />
            </FormField>
            <FormField label="Company">
              <Select
                options={companies}
                selectedAriaLabel="Selected"
                onChange={({detail}) => handleChange("company", detail.selectedOption)}
                placeholder="Choose Company"
                selectedOption={attendee.company}
                readOnly = {!edit}
              />
            </FormField>
            <FormField label="Event Name">
              <Select
                options={events}
                selectedAriaLabel="Selected"
                onChange={({detail}) => handleChange("eventName", detail.selectedOption)}
                placeholder="Choose Event"
                selectedOption={attendee.eventName}
                readOnly = {!edit}
              />
            </FormField>
            <FormField label="Event Date">
              <DatePicker
                disabled = {!edit}
                onChange={({detail}) => handleChange("attDate", detail.value)}
                value={attendee.attDate || ""}
                openCalendarAriaLabel={selectedDate =>
                  "Choose Date" +
                  (selectedDate
                    ? `, selected date is ${selectedDate}`
                    : <div></div>)
                }
                nextMonthAriaLabel="Next month"
                placeholder="YYYY/MM/DD"
                previousMonthAriaLabel="Previous month"
                todayAriaLabel="Today"
                onBlur={(detail) => {
                  handleSave(name, fields)
                }}
              />
            </FormField>
          </SpaceBetween>
        </Form>
      </Modal>

      <Modal
        visible={delModal}
        onDismiss={() => setDelModal(false)}
        size="small"
        header={<div>Are you sure?</div>}
      >
        <Box textAlign="center">
          <Button
            id={delIDX}
            variant="primary"
            icon="close"
            onClick={(detail) => {
              handleRemoveItem();
              handleSave(name, fields)
            }}
          >
            Delete
          </Button>
        </Box>
      </Modal>

      <Table
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: item => item.name || "-",
            width: 200,
            maxWidth: 200
          },
          {
            id: "designation",
            header: "Designation",
            cell: item => item.designation || "-",
            width: 200,
            maxWidth: 200
          },
          {
            id: "email",
            header: "Email",
            cell: item => item.email || "-",
            width: 200,
            maxWidth: 200
          },
          {
            id: "company",
            header: "Company",
            cell: item => (item.company !== null && item.company !== undefined) ? item.company.label : "-",
            width: 200,
            maxWidth: 200
          },
          {
            id: "event",
            header: "Event",
            cell: item => (item.eventName !== null && item.eventName !== undefined) ? item.eventName.label : "-",
            width: 200,
            maxWidth: 200
          },
          {
            id: "date",
            header: "Date",
            cell: item => (item.attDate !== null) ? item.attDate : "-",
            width: 200,
            maxWidth: 200
          },
          {
            id: "delete",
            header: "Delete",
            cell: item => <Button iconName="close"
                                  variant="icon"
                                  disabled = {!edit}
                                  onClick={() => handleRemove(fields.indexOf(item) || 0)}/>,
            width: 80,
            maxWidth: 80
          },
          {
            id: "edit",
            header: "Edit",
            cell: item => <Button iconName="edit"
                                  variant="icon"
                                  disabled={!edit}
                                  onClick={() => handleEdit(fields.indexOf(item) || 0)}/>,
            width: 80,
            maxWidth: 80
          },

        ]}

        wrapLiness
        items={fields}
        loadingText="Loading resources"
        empty={
          <Box textAlign="center" color="inherit">
            <b>no attendees</b>
          </Box>
        }
        header={<ColumnLayout columns={2}>
          <Header>Attendees </Header>

          <Box textAlign="right">

              <Button iconAlign="right"
                      iconName="add-plus"
                      variant="primary"
                      disabled = {!edit}
                      onClick={(detail) => handleNewAddAttendee()}
              >
                Add
              </Button>

          </Box>
        </ColumnLayout>}
      />

    </div>
  );
}

export default DAttendees;
