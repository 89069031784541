import React, {useState} from "react";
import {Button, Input, Modal, Spinner, Checkbox} from "../../aws-ui-components";
import "../../styles/servicehomepage.css";
import {connect} from "react-redux";
import {addPartnertoProfile, fetchMyProfile} from "../../actions";

class AddPartner extends React.Component  {

  constructor(props) {
    super(props);

    this.state = {
      partnerInfo:{
        spmsid: "",
        highPotential:true
      },
      loading: false,
      addStatus: "",
      modalVisible: true
    }
  }


  handleChange = (type, value) => {
    switch(type){
      case "spmsid":
        if (!/[^0-9]/.test(value)) {
          this.setState({partnerInfo:{...this.state.partnerInfo, spmsid:value }})
        }
        break;
      case "highPotential":
        this.setState({partnerInfo: {...this.state.partnerInfo, highPotential:value}});

    }

  }

  handleAdd = () => {
    this.setState({loading: true});
    this.setState({addStatus: ""})
    this.props.addPartnertoProfile(this.state.partnerInfo).then( (resp) => {
      this.setState({loading: false});
      if (resp == null){
        this.state.partnerInfo.spmsid="";
        this.state.loading=false;
        this.state.addStatus="";
        this.props.handleAddPartner();
      } else {
        if (resp === 409)
          this.setState({addStatus: "Partner already exist! Click (x) to close."})
        else
          this.setState({addStatus: "Something went wrong! Click (x) to close."})
      }
    });
  }

  dismissModal = () => {
    this.state.partnerInfo.spmsid="";
    this.state.loading=false;
    this.state.addStatus="";
    this.props.onDismiss();
  }


  render(){

    return (
      <div>
        <Modal
          visible={this.props.visible}
          size="small"
          header ={<div > Add Partner </div>}
          onDismiss={ this.dismissModal}
        >
        <Input
          type="text"
          autoFocus
          placeholder="Enter Partner SPMS ID"
          onChange={event =>
            this.handleChange("spmsid",event.detail.value)
          }
          value={this.state.partnerInfo.spmsid}
        />
          <Checkbox
            onChange={({ detail }) =>
              this.handleChange("highPotential",detail.checked)
            }
            checked={this.state.partnerInfo.highPotential}
          >
            High-Potential Partner?
          </Checkbox>

        <Button iconName="add-plus" onClick={this.handleAdd} variant="primary"> Add Partner</Button>
        { (this.state.loading) ? <Spinner size="normal" /> : <div/>}
        { (this.state.addStatus.length > 0) ? this.state.addStatus : <div/>}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    myProfile: state.myProfile,
  };
}

export default connect(mapStateToProps, {
  fetchMyProfile, addPartnertoProfile
})(AddPartner);

