import React from "react";
import Linker from "./utils/Linker"

export const COLUMN_DEFINITIONS = [
  {
    id: "sessionID",
    header: "Session ID",
    cell: item => item.sessionID,
    minWidth: "160px"
  },
  {
    id: "sessionName",
    header: "Session Name",
    cell: item => (
      <Linker link={"/session?"+item.sessionID} name={item.sessionName} id={item.sessionID}/>
    ),
    minWidth: "100px",
    sortingField: "sessionName"
  },
  {
    id: "sessionType",
    cell: item => item.sessionType.label,
    header: "Session Type",
    minWidth: "100px",
  },
  {
    id: "partnerName",
    header: "Partner Name",
    cell: item => item.partnerName = (item.partnerInfo.name || ""),
    minWidth: "100px",
    sortingField: "partnerName"
  },
  {
    id: "partnerID",
    header: "Partner ID",
    cell: item => item.partnerID,
    minWidth: "50px",
  },
  {
    id: "status",
    header: "Status",
    cell: item => item.status,
    minWidth: "80px",
  },
  {
    id: "sessionTypeLabel",
    header: "Session Type",
    cell: item => item.sessionTypeLabel = (item.sessionType.label || ""),
    minWidth: "80px",
    sortingField: "sessionTypeLabel"
  },
  {
    id: "createdOn",
    header: "Created On",
    cell: item => dateConvert(Number(item.createdOn)),
    minWidth: "100px",
    sortingField: "createdOn"
  },
  {
    id: "createdBy",
    header: "Created By",
    cell: item => item.createdByUID,
    minWidth: "100px",
  }
];


export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: "Session Attributes",
    options: [
      { id: "sessionName", label: "Session Name", editable: false },
      { id: "partnerName", label: "Partner Name", editable: false },
      { id: "sessionTypeLabel", label: "Session Type", editable: true},
      { id: "createdOn", label: "Created On", editable: true},
      { id: "sessionID", label: "Session ID", editable: true },
      { id: "createdBy", label: "created By", editable: true },
      { id: "partnerID", label: "partner ID", editable: true }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: "10 sessions" },
  { value: 30, label: "30 sessions" },
  { value: 50, label: "50 sessions" }
];

export const CUSTOM_PREFERENCE_OPTIONS = [
  { value: "table", label: "Table" }
];

export const DEFAULT_PREFERENCES = {
  pageSize: 10,
  visibleContent: [
    "sessionName",
    "sessionTypeLabel",
    "partnerID",
    "partnerName",
    "createdOn",
    "createdBy"
  ],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};


export const DEFAULT_PREFERENCES_SEARCH = {
  pageSize: 10,
  visibleContent: [
    "sessionName",
    "sessionTypeLabel",
    "partnerID",
    "partnerName",
    "createdOn",
    "createdBy"
  ],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};

const dateConvert = (value) =>{
  const d = new Date(value);
  return d.toDateString();
}

