import React from 'react';
import {Tabs, Box, Form, Container, Grid, Header, SpaceBetween} from "../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession, updateGoals, updateSession} from "../../actions";
import DGoalsForm from './forms/DGoalsForm';

class WBWGoals extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      goals: [],
    }
  }

  updateEventChange = () =>{

  }

  handleSave = (name, values) => {
    switch (name) {
      case "goals":
        this.state.goals = [...values];
        break;
    }
   // this.props.updateGoals(this).then(async()=>{
      //this.props.saveToDB();
   // });
  }

  componentWillUnmount() {
    //this.props.saveToDB();
  }

  render() {
    return(
      <div>
        <Tabs
          tabs={[
            {
              label: "Goals",
              id: "Goals",
              content: <div>
                <Box margin="l">
                  <DGoalsForm props={this.state.goals} name="goals" handleSave={this.handleSave} updateEventChange={this.updateEventChange}/>
                </Box>
              </div>
            },
          ]}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateGoals, updateSession
})(WBWGoals);