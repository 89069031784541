import React from "react";
import {
  Box,
  ColumnLayout,
  Container,
  Grid,
  SpaceBetween
} from "../../aws-ui-components";
import MainHeader from "./MainHeader";
import {connect} from "react-redux";
import {getUser} from "../../actions";

class HomeContent extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    return (
        <Box>
          <MainHeader />
          <Box padding={{ top: "xxl" }}>
            <Grid
              gridDefinition={[
                { colspan: { default: 9}, offset: { default:1} }
              ]}
            >
              <Box>
                <SpaceBetween size="l">
                  <Box>
                    <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                      How it works?
                    </Box>
                    <Container>
                      <Box>
                        <Box>1. Create a session, make sure you have the right Partner SPMS ID</Box>
                        <Box>
                          2. Review the partner information, and leverage useful links provided
                        </Box>
                        <Box>
                          3. Facilitate partner conversations using this tool (eg. envisioning session)
                        </Box>
                        <Box>
                          4. Input as much of information as you can in the session
                        </Box>
                        <Box>
                          5. Update session status under Session Management
                        </Box>
                        <Box>
                          6. Leverage Tools section on the right, it provides you with necessary material to help you with the session
                        </Box>
                      </Box>
                    </Container>
                  </Box>

                  <Box>
                    <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                      Benefits and features
                    </Box>
                    <Container>
                      <ColumnLayout columns={2} variant="text-grid">
                        <Box>
                          <Box variant="h3" padding={{ top: "n" }}>
                            Easy to conduct Workshops
                          </Box>
                          <Box>
                            The aim of this application is to provide PMSAs an easier way
                            to research and conduct partner work-stream/workshops.
                            <p/>
                            <Box>
                              1. This tool aggregates information from several systems and presents
                              it in one interface for you.
                            </Box>
                            <Box>
                              2. Helps you to conduct your sessions efficiently and effectively as
                              the tool guides you through different activities.
                            </Box>
                            <Box>
                              3. Generates narration documentation, roadmaps etc.
                            </Box>
                            <Box>
                              4. Store your artifacts and deliverables by uploading them into this tool for perusal.
                            </Box>

                            You can also keep your artifacts and deliverables created outside of this tool
                            by uploading them into this tool for perusal.
                          </Box>
                        </Box>
                        <Box>
                          <Box variant="h3" padding={{ top: "n" }}>
                            Digitalization of partner work-streams
                          </Box>
                          <Box variant="p">
                            Storing PMSA WorkStream data in a centralized data store
                            has many benefits.  Some of them are -
                          </Box>
                          <Box>
                            1. PMSAs can get a snapshot of critical partner information
                            in this tool to conduct research
                          </Box>
                          <Box>
                            2. Information is now easily found
                          </Box>
                          <Box>
                            3. Gain valuable insights into partners
                          </Box>
                          <Box>
                            4. Better visibility through reporting and dashboards
                          </Box>
                        </Box>
                        <Box>
                          <Box variant="h3" padding={{ top: "n" }}>
                            Reporting and Dashboards (Coming soon...)
                          </Box>
                          <Box variant="p">
                            This tool provides reporting and dashboards on different
                            aspects w.r.t the sessions entered.
                          </Box>
                        </Box>
                        <Box>
                          <Box variant="h3" padding={{ top: "n" }}>
                            Auto Recommendations (Coming soon...)
                          </Box>
                          <Box variant="p">
                            This tool will provide several recommendations
                            and insights automatically using AI/ML and data analytics
                            in realtime, for more accurate planning.
                          </Box>
                        </Box>
                      </ColumnLayout>
                    </Container>
                  </Box>
                </SpaceBetween>
              </Box>
            </Grid>
          </Box>
        </Box>
    )
  }
}

const mapStateToProps = (state) => {
  return {state};
}

export default connect(mapStateToProps, {
  getUser
})(HomeContent);
