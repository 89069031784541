import React from 'react';
import SessionHeader from "./SessionHeader";
import history from '../../history';
import {
  Box,
  ColumnLayout,
  Container,
  Header,
  Link,
  Modal,
  SpaceBetween,
  Spinner,
  StatusIndicator
} from "../../aws-ui-components";
import {connect} from "react-redux";
import {fetchSession} from "../../actions";

class PartnerProfile extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.turnOffFlashBar();
  }

  exit = () => {
    history.push("/");
  }

  render() {

    var partnerInfo = {};
    var partnerName = "";
    var partnerTier = "";
    var aceEligible = "";
    var statusAce = "";
    var sfdc_url = "";
    var spmid = "";
    var partnerWebsite = "";
    var description = "";
    var sdps = [];
    var competencies = [];
    var IDProgramStatus = "";
    var WAPPStatus = "";
    var PPBID = "";
    var sfdcID = "";
    var programs = [];

    if (this.props.store.sessionInfo)
      if (this.props.store.sessionInfo.session)
        if (this.props.store.sessionInfo.session.partnerInfo !== undefined) {
          partnerInfo = this.props.store.sessionInfo.session.partnerInfo;
          partnerName = partnerInfo.name || "No info.";
          partnerTier = partnerInfo.tier || "";
          aceEligible = partnerInfo.ace_eligibility || "No";
          statusAce = (partnerInfo.ace_eligibility === "Yes") ? "success" : "error";
          spmid = partnerInfo.spmsid || "";
          partnerWebsite = partnerInfo.partner_website || "";
          description = partnerInfo.description || ""
          sdps = partnerInfo.sdps || []
          competencies = partnerInfo.competencies || [];
          PPBID = "";
          sfdcID = partnerInfo.sfdc_id || "";
          programs = partnerInfo.programs || [];
          sfdc_url = "https://aws-crm.my.salesforce.com/" + sfdcID || "";
        }

    const style = {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }

    if (partnerName.length === 0) {
      return (
        <div>
          <Modal
            visible={true}
            size="small"
            header={<div style={style}> Loading Session... </div>}
          >
            <div style={style}><Spinner size="normal"/></div>
          </Modal>
        </div>
      )
    } else
      return (
        <div>
          <Box padding={{top: "l"}} margin="l">
            <div>
              <SpaceBetween size="m">
                <div>
                  <SpaceBetween size="m">
                    <Container
                      header={
                        <Header
                          variant="h3"
                          description=""
                        >
                          Partner Profile
                          <Box>
                            (as on
                            {(this.props.store.sessionInfo.session !== undefined) ? " " + dateConvert(Number(this.props.store.sessionInfo.session.createdOn)) : ""}
                            )
                          </Box>
                        </Header>
                      }
                    >
                      <SpaceBetween size="l">
                        <div>
                          <ColumnLayout columns={3} variant="text-grid">
                            <SpaceBetween size="l">
                              <ValueWithLabel label="Partner Name"> {partnerName} </ValueWithLabel>
                              <ValueWithLabel label="SPMS ID">
                                {spmid}
                              </ValueWithLabel>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                              <ValueWithLabel label="Tier">{partnerTier} Tier</ValueWithLabel>
                              <ValueWithLabel label="ACE Eligiblity">
                                <StatusIndicator type={statusAce}> {aceEligible}</StatusIndicator>
                              </ValueWithLabel>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                              <ValueWithLabel label="SFDC URL">
                                <Link
                                  href={sfdc_url}
                                  external={true}
                                >
                                  AWS CRM
                                </Link>
                              </ValueWithLabel>
                              <ValueWithLabel label="Partner Website">
                                <Link
                                  href={partnerWebsite}
                                  external={true}
                                >
                                  {partnerName} website
                                </Link>
                              </ValueWithLabel>
                            </SpaceBetween>
                          </ColumnLayout>
                        </div>
                        <Container
                          header={
                            <Header
                              variant="h4"
                              description=""
                            >
                              Partner Summary:
                            </Header>
                          }
                        >
                          {description}
                        </Container>

                        <Container
                          header={
                            <Header
                              variant="h4"
                              description=""
                            >
                              Partner Designations and Programs:
                            </Header>
                          }
                        >
                        <ColumnLayout columns={3} variant="text-grid">
                          <div>
                            <h5> Service Deliver Designations </h5>
                            {
                              sdps.map((item, index) => {
                                return (<Box key={index}>
                                  <StatusIndicator type="success">{item}</StatusIndicator>
                                </Box>)
                              })
                            }
                          </div>
                          <div>
                            <h5>Competency Designations</h5>
                            {
                              competencies.map((item, index) => {
                                return (<Box key={index}>
                                  <StatusIndicator type="success">{item}</StatusIndicator>
                                </Box>)
                              })
                            }
                          </div>
                          <div>
                            <h5>APN Programs</h5>
                            {
                              programs.map((item, index) => {
                                return (<Box key={index}>
                                  <StatusIndicator type="success">{item}</StatusIndicator>
                                </Box>)
                              })
                            }
                          </div>
                        </ColumnLayout>
                        </Container>
                        <Container
                          header={
                            <Header
                              variant="h4"
                              description=""
                            >
                              Additional Partner Data (Links):
                            </Header>
                          }
                        >
                          <Link
                            href={"https://pipeline.builder.apn.a2z.com/#/search"}
                            external={true}
                          >
                            Pipeline Builder portal Link for {partnerName}
                          </Link>
                          <p/>
                          <Link
                            //href={"https://discover.awspartner.com/partner/" + sfdcID +"/"+partnerName}
                            href={"https://discover.awspartner.com/?kw=" + partnerName + "&type=consultingPartners"}
                            external={true}
                          >
                            Partner Discovery portal Link for {partnerName}
                          </Link>
                        </Container>

                      </SpaceBetween>

                    </Container>

                  </SpaceBetween>
                </div>
              </SpaceBetween>
            </div>
          </Box>
        </div>
      )
  }
}

const ValueWithLabel = ({label, children}) => (
  <div>
    <Box margin={{bottom: 'xxxs'}} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const dateConvert = (value) => {
  const d = new Date(value);
  return d.toUTCString();
}

const mapStateToProps = (state) => {
  //console.log(state);
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession
})(PartnerProfile);