import React, { useEffect, useState, Component}  from "react";
import {connect} from 'react-redux';
import "../../styles/servicehomepage.css";
import '@amzn/awsui-global-styles/polaris.css';
import "../../styles/servicehomepage.css";
import {
  AppLayout
} from "../../aws-ui-components";

import HomeNav from "./HomeNav";
import MyPartnersTable from "./MyPartnersTable";
import {addPartnertoProfile, fetchMyProfile} from "../../actions";
import MainTools from "./MainTools";

class MyPartners extends React.Component {

  constructor(props) {
    super(props);
  }

  componentDidMount () {
    this.props.fetchMyProfile();
  }

  render() {
    return (
      <AppLayout
        disableContentPaddings={true}s
        navigation={<HomeNav />}
        content={ <MyPartnersTable /> }
        contentType="default"
        navigationOpen={true}
        tools={<MainTools/>}
        toolsHide={false}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    myProfile: state.myProfile,
  };
}

export default connect(mapStateToProps, {
  fetchMyProfile, addPartnertoProfile
})(MyPartners);

