import React, { Fragment } from "react";
import { RadioGroup, Box } from "../../../../aws-ui-components";

const Radio = ({ field, fieldChanged, fieldBlur, type, value }) => {
  return (
    <Box padding={{ top: "xs" }}>
      <div key={field._uid}>
        <Box>
          <label htmlFor={field._uid}>{field.label}</label>
        </Box>
        <Box>
          <RadioGroup
            id={field._uid}
            name={field._uid}
            value={value.answer}
            items={field.options}
            onChange={detail => {
              fieldChanged(field._uid, detail.detail.value, field.label, "radio", field.options );
            }}
            onBlur={() => {
              fieldBlur(value);
            }}
          />
        </Box>
      </div>
    </Box>
  );
};

export default Radio;
