import React from "react";
import { Box, Textarea} from "../../../../aws-ui-components";

const Field = ({ field, fieldChanged, fieldBlur, type, value, disabled }) => {
  let size = 0;
  if (value.answer){
    value.answer.split("\n").map((line) =>
    {
      size = size + line.length/210 + 1;
    })
  };
  size = (size === 0)?1:size

  return (
    <Box key = {field._uid} padding={{ top: "xs" }}>
      <div key={field._uid}>
        <NewlineText key={field._uid} text={field.label} />
        <Textarea
          rows = {size}
          name={field._uid}
          value={value.answer}
          disabled = {disabled}
          onChange={detail => {
            fieldChanged(field._uid, detail.detail.value, field.label, "textarea", "");
          }}
          onBlur={() => {
            fieldBlur(value);
          }}
        />
      </div>
    <p/>
    </Box>
  );
};

function NewlineText(props) {
  let newText = "";
  if(props.text !== null && props.text !== "" && props.text !== undefined) {
    const text = props.text;
    newText = text.split('\n').map((str, index) => <div style={{ fontWeight: 'bold' }} key={index.toString()}>{str}</div>);
  }
  return newText;
}

export default Field;
