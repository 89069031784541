export default (state = [], action) => {

  switch(action.type) {
    case 'FETCH_UPLOAD_FILES_LIST':
      return action.payload;
    case 'FETCH_UPLOAD_COMPLETE':
      return {...state,uploads: {...state.uploadsInfo, status:{uploadStatus:action.payload}}};
    default:
      return state;
  }
}
