import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Textarea,
  DatePicker,
  Container,
  Grid,
  Input,
  SpaceBetween,
  Modal,
  ExpandableSection,
  ColumnLayout, Select
} from "../../../aws-ui-components";
import "../../../styles/servicehomepage.css";
import {GOAL_TYPE_OPTIONS} from "../resources/WBWGoalsSelection"
import {MONTH_NAMES} from "../../../resources/utils";

const DGoalsForm = ({props, name, handleSave, updateEventChange}) => {
  const [fields, setFields] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [delIDX, setDelIDX] = useState(null);

  const y1d = new Date();
  const y1 = y1d.getFullYear();
  const m = y1d.getMonth()+1;
  const y2 = y1+1;
  const y3 = y2+1;
  const y4 = y3+1;

  const month = MONTH_NAMES[m];

  const year1 = month+"-"+y1+" to "+month+"-"+y2;
  const year2 = month+"-"+y2+" to "+month+"-"+y3;
  const year3 = month+"-"+y3+" to "+month+"-"+y3+1;

  useEffect(() => {
    if (props !== undefined){
      setFields(props);
    }
  },[]);

  function handleChange(i,name, event) {
    const values = fields;
    values[i] = {...values[i], [name]: event} ;
    setFields([...values]);
    updateEventChange(true);
  }

  function handleAdd(e) {
    e.preventDefault();
    const values = fields;
    values.push({ name: null, category: null, qty: null, goalYear: null, goalQuarter: null, status: null, origin: null, details: null});
    setFields([...values]);
    updateEventChange(true);
  }

  function handleRemove(i, e) {
    setDelIDX(i);
    setDelModal(true);
    updateEventChange(true);
  }

  function handleRemoveItem() {
    setDelModal(false);
    const values = fields;
    values.splice(delIDX, 1);
    setFields([...values]);
    updateEventChange(true);
  }

  return (
    <div className="App">
        <SpaceBetween direction="vertical" size="xxs">
          {
            fields.map((field, idx) => {
            return (
              <div key={`${field}-${idx}`}>
                <Modal
                  visible={delModal}
                  onDismiss={() => setDelModal(false)}
                  size="small"
                  header={<div>Delete: Are you sure?</div>}
                >
                  <Box textAlign="center">
                    <Button
                      id={`${field}-${idx}`}
                      variant="primary"
                      icon="close"
                      onClick={(detail) => {
                        handleRemoveItem();
                        handleSave(name, fields)
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Modal>
                <Grid
                  gridDefinition={[{ colspan: 11, offset: { default: 0 } }, { colspan: 1, offset: { default: 0 }  }]}
                >

                    <Container>
                      <ColumnLayout columns={3} borders="vertical"  >
                        <label>
                          Goal Category
                          <Select
                            options={GOAL_TYPE_OPTIONS}
                            selectedAriaLabel="Selected"
                            onChange={({ detail }) => handleChange(idx, "category", detail.selectedOption)}
                            placeholder="Choose Goal Category"
                            selectedOption={field.category}
                            onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          />
                        </label>
                        <label>
                          Goal sub-category (optional)
                          <Input
                            onChange={({ detail }) => handleChange(idx, "goal", detail.value)}
                            value = {field.goal}
                            onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                            placeholder="Enter Goal Qty"
                          />
                        </label>
                        <label>
                          Goal Qty (optional)
                          <Input
                            onChange={({ detail }) => handleChange(idx, "qty", detail.value)}
                            value = {field.qty}
                            onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                            placeholder="Enter Goal Qty"
                          />
                        </label>

                          <label>
                            Goal Year
                            <Select
                              options={[{label: y1, value:y1},{label: y2, value:y2},{label: y3, value:y3}, {label: y4, value:y4},]}
                              selectedAriaLabel="Selected"
                              onChange={({ detail }) => handleChange(idx, "goalYear", detail.selectedOption)}
                              placeholder="Choose Year"
                              selectedOption={field.goalYear}
                              onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                            />
                          </label>
                          <label>
                            Goal Quarter
                            <Select
                              options={[{label: "Q1", value: "q1"},{label: "Q2", value:"q2"},{label: "Q3", value:"q3"},{label: "Q4", value:"q4"},]}
                              selectedAriaLabel="Selected"
                              onChange={({ detail }) => handleChange(idx, "goalQuarter", detail.selectedOption)}
                              placeholder="Choose Year"
                              selectedOption={field.goalQuarter}
                              onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                            />
                          </label>
                       <div></div>
                        <label>
                          Goal Status
                          <Select
                            options={[{label: "Prospect", value: "q1"},{label: "In Progress", value:"q2"},{label: "Completed", value:"q3"},]}
                            selectedAriaLabel="Selected"
                            onChange={({ detail }) => handleChange(idx, "status", detail.selectedOption)}
                            placeholder="Choose Status"
                            selectedOption={field.status}
                            onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          />
                        </label>
                        <label>
                          Goal Origin
                          <Select
                            options={[{label: "Oktank-WBW", value: "q1"},{label: "Oktank-CCoEP", value:"q2"},]}
                            selectedAriaLabel="Selected"
                            onChange={({ detail }) => handleChange(idx, "origin", detail.selectedOption)}
                            placeholder="Choose Origin"
                            selectedOption={field.origin}
                            onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          />
                        </label>

                      </ColumnLayout>
                      <ExpandableSection header="Details">
                        <Textarea
                          onChange={({ detail }) => handleChange(idx, "details", detail.value)}
                          value = {field.details}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {(field.details !== null)? (field.details.length/160 + field.details.split("\n").length): 1}
                          placeholder="Enter goal details..."
                        />
                      </ExpandableSection>
                    </Container>

                <Box float="right">
                  <Button
                    id={`${field}-${idx}`}
                    variant="icon"
                    iconName="close"
                    onClick={(detail) => {handleRemove(idx, detail); handleSave(name, fields)}}
                  />
                </Box>
                </Grid>
              </div>

            );
          })}
          <Button  iconAlign="right"
                   iconName="add-plus"
                   variant="primary"
                   onClick={(detail) => {handleAdd(detail); handleSave(name, fields)}}
          >Add</Button>
        </SpaceBetween>
    </div>
  );
}

export default DGoalsForm;
