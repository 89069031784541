import React from "react";
import { Box, Input} from "../../../../aws-ui-components";

const Field = ({ field, fieldChanged, type, value }) => {
 // console.log(value);
  return (
    <Box padding={{ top: "l" }}>
      <div key={field._uid}>
        <Box>
          <label htmlFor={field._uid}>{field.label}</label>
        </Box>
        <Box>
          <Input
            type={type}
            id={field._uid}
            name={field._uid}
            value={value}
            onChange={detail => {
              // Notify the main state list of the new value
              fieldChanged(field._uid, detail.detail.value, "input", "");
            }}
          />
        </Box>
      </div>
    </Box>
  );
};

export default Field;
