import React from "react";

import PartnerProfile from '../PartnerProfile';
import SessionUploads from '../SessionUploads';
import WBWPreQ from './WBWPreQ';
import WBWSWOT from './WBWSWOT';
import WBWPRFAQ from './WBWPRFAQ';
import WBWPRStmt from './WBWPRStmt';
import WBWRecommendations from './WBWRecommendations';
import WBWStrategy from './WBWStrategy';
import WBWOpp from './WBWOpp';
import WBWNarrative from './WBWNarrative';
import WBWRoadmap from './WBWRoadmap';
import WBWUni from "./WBWUni";
import WBWNotes from "./WBWNotes"

export const items = [
  { type: "link", text: "Partner Profile", href: PartnerProfile },
  { type: "link", text: "Pre-Questionnaire", href: WBWPreQ },
  { type: "divider"},
  {
    type: "link-group", text: <h4>Envisioning</h4>,
    items: [
      {type: "link", text: "SWOT Analysis", href: WBWSWOT},
      {type: "link", text: "Unicorn Super Powers", href: WBWUni},
      {type: "link", text: "Where can you innovate?", href: WBWOpp},
      {type: "link", text: "PR Statements", href: WBWPRStmt},
      {type: "link", text: "PR FAQs", href: WBWPRFAQ}
    ]
  },
  { type: "divider"},
  {
    type: "link-group", text: <h4>Recommendations</h4>,
    items: [
      {type: "link", text: "Overall Approach", href: WBWStrategy},
      {type: "link", text: "Recommendations", href: WBWRecommendations},
    ]
  },
  { type: "divider"},
  {
    type: "link-group", text: <h4>Rendered Outputs</h4>,
    items: [
      {type: "link", text: "Complete Narrative", href: WBWNarrative},
      {type: "link", text: "Consolidated Notes", href: WBWNotes},
      {type: "link", text: "Roadmap", href: WBWRoadmap},
    ]
  },
  { type: "divider"},
  { type: "link", text: "Additional Artifacts (uploads)", href: SessionUploads },
];