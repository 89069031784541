import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
} from "@amzn/awsui-components-react";
// import { withAuthenticator } from "aws-amplify-react";
import { applyMode, Mode, applyDensity, Density, disableMotion } from '@amzn/awsui-global-styles';

import Amplify from "aws-amplify";
import config from "../aws-exports";
import { Auth, Hub } from "aws-amplify";
import Home from "./home/Home";
import {Route, Router} from "react-router-dom";
import history from "../history";
import MySessions from "./home/MySessions";
import MyPartners from "./home/MyPartners";
import Sessions from "./session/Session";
import CreateSession from "./home/CreateSession";
import Insights from "./home/Insights";
import SearchSessions from "./home/SearchSessions";
import Partner from "./partner/Partner"

//console.log(config);

Amplify.configure(config);

const App = () => {
  const [user, setUser] = useState(null);
  applyMode(Mode.Dark);
  //applyDensity(Density.Compact);
  disableMotion(true);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      // eslint-disable-next-line default-case
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          console.log("Sign in failure", data);
          break;
      }
    });

    getUser().then((userData) => {
      setUser(userData);
    });
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => console.log("Not signed in"));
  }

  return (
    <div >
      {user ? (
        <div>
          <Router history={history}>
            <Route path="/" exact component={Home} />
            <Route path="/myPartners"  component={MyPartners} />
            <Route path="/mySessions"  component={MySessions} />
            <Route path="/session" exact component={Sessions} />
            <Route path="/createSession"  component={CreateSession} />
            <Route path="/searchSession"  component={SearchSessions} />
            <Route path="/insights"  component={Insights} />
            <Route path="/partner" exact component={Partner} />
          </Router>
        </div>
      ) : (
        <Grid gridDefinition={[{ colspan: 6, offset: 3 }]}>
          <Box className="login-form" textAlign="center" color="inherit">
            <h1 color="#ff9900;">Welcome to PWMT!</h1>
            <Button
              variant="primary"
              onClick={() =>
                Auth.federatedSignIn({ provider: "AmazonFederate" })
              }
            >
              Login With Amazon Midway
            </Button>
          </Box>
        </Grid>
      )};
    </div>
  );
};

export default App;