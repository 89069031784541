import React, {useEffect, useState} from "react";
import {
  Box,
  Spinner,
  Toggle,
  Modal
} from "../../../../aws-ui-components";
import "../../../../styles/servicehomepage.css"
import {CreateSessionHold, fetchSession, RemoveSessionHold} from "../../../../actions"
import {connect} from "react-redux";
import history from "../../../../history";

class SessionHold extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      loading: false,
      notice: "",
      modalView: false
    };
  }

  componentWillUnmount() {
    if(this.state.edit === true)
      this.props.RemoveSessionHold(this.props.sessionID);
  }

  handleToggle = (edit) => {
    this.setState({loading:true});
    if (edit === true) {
      this.props.CreateSessionHold(this.props.sessionID).then((data)=>{

        if (data.info.hold === false || data.statusCode > 400) {
          //console.log(data)
          this.setState({
            notice: data.info.message || "",
            modalView: true,
            edit: false,
            loading: false
          });
          this.props.sessionHoldHandler(false);
        } else {
          //console.log(data)
          this.setState({
            loading: false,
            edit: true
          })
          this.props.sessionHoldHandler(true);
        }

      });
    } else {
        this.setState({loading: true});

         this.props.RemoveSessionHold(this.props.sessionID).then((data) => {

           console.log(data);
           if (data.info.hold === false){
             this.props.sessionHoldHandler(false);
             this.setState({loading: false, edit: false});
           } else {
             this.props.sessionHoldHandler(true);
             this.setState({
               notice: "Encountered an issue to unHold the session!",
               modalView: true,
               edit: true,
               loading: false
             });
           }

        });

    }
  }

  render() {
    return(
      <Box float="right" margin="xs">
        { this.state.loading ?
          <Spinner size="normal"/> :
          <Toggle
            onChange={({detail}) =>
              this.handleToggle(detail.checked)
            }
            checked={this.state.edit}
          >
            Edit
          </Toggle>
        }
        {this.state.notice?
          <div>
            <Modal
              onDismiss={() => this.setState({modalView:false})}
              visible={this.state.modalView}
              closeAriaLabel="Close modal"
              size="small"
              header="Alert!"
            >
              {this.state.notice}
            </Modal>

          </div> : <div/> }
      </Box>
    )
  }
}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  CreateSessionHold,RemoveSessionHold, fetchSession
})(SessionHold);

