import React from "react";
import "../../styles/servicehomepage.css";
import {
  AppLayout,
  Flashbar,
  Button, Box, Modal, SpaceBetween, Toggle
} from "../../aws-ui-components";
import {connect} from "react-redux";

import Header from "./Header";
import PartnerNav from "./PartnerNav";
import PartnerProfile from './PartnerProfile';
import Sessions from './Sessions';
import Goals from "./Goals";
import SessionsTable from "./SessionsTable";
import {searchSessions} from "../../actions";

class Partner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      uri: props.location.search.substring(1),
      error: false,
      errMsg: null,
      component: <PartnerProfile spmsid={props.location.search.substring(1)}/>,
      notification: [],
      notice:false,
      noticeComp: false,
      unsaved: false,
      modalVisible: false
    };
  }

  onNavChange = (event) => {
    this.setState({notification: [{
        type: "success",
        content: "All is well",
        dismissible: false,
      }]});

    switch (event) {
      case "ContentPartner":
        this.setState({component: <PartnerProfile spmsid={this.state.uri}/>})
        break;
      case "ContentSessions":
          this.setState({component: <Sessions spmsid={this.props.location.search.substring(1)}/>, notification:[], noticeComp:false})
        break;
      case "ContentGoals":
        this.setState({component: <Goals errorInComponent={this.errorInComponent} error={this.state} updateEventChange={this.updateEventChange} saveToDB={this.saveToDB} />, notification:[], noticeComp:false})
        break;

      default:
        this.setState({component:  <PartnerProfile />, notification:[], noticeComp:false})
    }
  }

  render() {

    return (
      <AppLayout
        disableContentPaddings={true}
        navigation={<PartnerNav navChange={this.onNavChange}/>}
        content={<div>
          <Header title={this.props.spmsid}/>
          {this.state.component}
        </div>}
        navigationOpen={true}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    store: state,
    loading: false,
    sessions: state.sessionInfo
  };
}

export default connect(mapStateToProps, {
  searchSessions
})(Partner);


