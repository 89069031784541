import React from 'react';
import history from '../../history';
import {
  Box, Button,
  ColumnLayout,
  Container, Input,
  Link,
  Modal,
  SpaceBetween,
  Spinner,
  StatusIndicator, Table
} from "../../aws-ui-components";
import {connect} from "react-redux";

import Header from "./Header";
import {COLUMN_DEFINITIONS, DEFAULT_PREFERENCES} from "./resources/my-partner-sessions-table-config";
import {searchSessions} from "../../actions";
import SessionsTable from "./SessionsTable";

class Sessions extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      searchResults : [],
      loading: true,
    }
  }

  componentDidMount = () => {
    this.props.searchSessions("partner_id", this.props.spmsid).then(async ()=>{
      await this.setState({searchResults: [this.props.sessions.search_sessions]});
      await this.setState({loading: false});
    });
  }


  render() {
    return  <SessionsTable session={this.state.searchResults} spmsid={this.props.spmsid}/>
  }
}

const mapStateToProps = state => ({
  sessions: state.sessionInfo,
  auth: state.auth
});

export default connect(mapStateToProps,{
  searchSessions
  })(Sessions);
