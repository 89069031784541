// Reducers
import {combineReducers} from 'redux';
import sessionsReducer from "./sessionsReducer";
import userProfileReducer from "./userProfileReducer";
import uploadsReducer from "./uploadsReducer";
import downloadFile from "./downloadFile";
import ExcelUploadReducer from "./ExcelUploadReducer";
import authReducer from "./authReducer";
import sessionsHoldReducer from "./sessionsHoldReducer";
import selectedReducer from "./selectedReducer";
import partnersReducer from "./partnersReducer";

export default combineReducers({
  sessionInfo: sessionsReducer,
  auth: authReducer,
  uploadsInfo: uploadsReducer,
  downloadFile: downloadFile,
  excelUploadState: ExcelUploadReducer,
  myProfile: userProfileReducer,
  sessionHold: sessionsHoldReducer,
  selected: selectedReducer,
  partnerInfo: partnersReducer
})