/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

//d16x9jsru5lzlx.cloudfront.net
//redirectSignIn: "http://localhost:3000", redirectSignOut: "http://localhost:3000",


const host = (window.location.hostname === "localhost")?"http://localhost:3000": "https://"+window.location.hostname
const EnvAttributes = () =>{
    switch (window.location.hostname){
        case "test.pwmt.pmsa.aws.dev":
            return {
                CognitoDomain: "pwmttest.auth.us-west-2.amazoncognito.com",
                CognitoIPool: "us-west-2:1a7a4066-cfbf-4056-b5a7-62ceb1e7d9fa",
                CognitoUPool: "us-west-2_XACJ7MGPK",
                CognitoWebClientID: "1h0c63cqi12s36jhpjeapr3bic"}
        case "localhost":
        case "dt2uttt3udrsp.cloudfront.net":
        case "dev.pwmt.pmsa.aws.dev":
            return {
                CognitoDomain: "pwmtdev.auth.us-west-2.amazoncognito.com",
                CognitoIPool: "us-west-2:73155313-b3ca-4959-b123-fd60ce586f9b",
                CognitoUPool: "us-west-2_wlseiVqj6",
                CognitoWebClientID: "23f91gb7vi5g7900rp7lah5daj"
            }
        case "pwmt.pmsa.aws.dev":
            return {
                CognitoDomain: "pwmt-prod.auth.us-west-2.amazoncognito.com",
                CognitoIPool: "us-west-2:d8067b01-e99b-4924-957a-f883e8ed9973",
                CognitoUPool: "us-west-2_7kbtkTrba",
                CognitoWebClientID: "40d0pmcog4fq98eob3u97b5bat"
            }
    }
}

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": EnvAttributes().CognitoIPool,
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": EnvAttributes().CognitoUPool,
    "aws_user_pools_web_client_id": EnvAttributes().CognitoWebClientID,
    "oauth": {
        domain: EnvAttributes().CognitoDomain,
        scope: ["profile", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: host, redirectSignOut: host,
        responseType: "code",
    },
    federationTarget: "COGNITO_USER_POOLS"
};

export default awsmobile;
