export const WBWPreQ = {
  wbw: {
    version: "0.2",
    preQ: [
      {
        component: "tab",
        _uid: "t1",
        label: "Business & Alignment",
        fields: [
          {
            component: "field_group",
            _uid: "t1_s1",
            label: "Executive Alignment",
            fields: [
              {
                component: "textarea",
                _uid: "t1_s1_q1",
                label: "Who is your Executive sponsor for AWS partnership?",
              },
              {
                component: "radio",
                _uid: "t1_s1_q2",
                label: "What level of commitment does your executives have with AWS partnership?",
                options: [
                  {value: "High", label: "High"},
                  {value: "Medium", label: "Medium"},
                  {value: "Low", label: "Low"}
                ],
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t1_s2",
            label: "Your Business",
            fields: [
              {
                component: "textarea",
                _uid: "t1_s2_q1",
                label: "What Geos do you currently cover?",
              },
              {
                component: "textarea",
                _uid: "t1_s2_q2",
                label: "What is your business model?",
              },
              {
                component: "textarea",
                _uid: "t1_s2_q3",
                label: "Who are your partners? (non cloud vendors)",
              },
              {
                component: "textarea",
                _uid: "t1_s2_q4",
                label: "Which cloud vendors have you partnered with?",
              },
              {
                component: "textarea",
                _uid: "t1_s2_q5",
                label: "Who is your primary cloud vendor?",
              }
            ]
          },
          {
            component: "field_group",
            _uid: "t1_s3",
            label: "Your Customers",
            fields: [
              {
                component: "mselect",
                _uid: "t1_s3_q1",
                label: "Who is your typical customers? (Size)",
                options: [
                  {value: "Large Enterprises", label: "Large Enterprises"},
                  {value: "Mid-Size corporations", label: "Mid-Size corporations"},
                  {value: "Small businesses", label: "Small businesses"}
                ],
              },
              {
                component: "mselect",
                _uid: "t1_s3_q2",
                label: "What verticals most of your  your customers belong to?",
                options: [
                  {value: "Finance & Insurance", label: "Finance & Insurance"},
                  {value: "Oil", label: "Oil"},
                  {value: "Manufacturing", label: "Manufacturing"},
                  {value: "Automobile", label: "Automobile"},
                  {value: "HealthCare", label: "HealthCare"},
                  {value: "Life Sciences", label: "Life Sciences"},
                  {value: "Media & Entertainment", label: "Media & Entertainment"},
                  {value: "Travel", label: "Travel"},
                  {value: "Hospitality", label: "Hospitality"},
                  {value: "Information Technology", label: "Information Technology"},
                ],
              },
              {
                component: "textarea",
                _uid: "t1_s3_q3",
                label: "Who is most difficult customer and why?",
              },
              {
                component: "textarea",
                _uid: "t1_s3_q4",
                label: "What are the top technologies most of your customers use?",
              },
              {
                component: "textarea",
                _uid: "t1_s3_q5",
                label: "How do you see your customers changing over 3 years?",
              },
              {
                component: "textarea",
                _uid: "t1_s3_q6",
                label: "What does majority of your customers seek from you?",
              }
            ]
          },
          {
            component: "field_group",
            _uid: "t1_s4",
            label: "Your Differentiation",
            fields: [
              {
                component: "mselect",
                _uid: "t1_s4_q1",
                label: "What areas are you very strong at?",
                options: [
                  {value: "Data and Analytics", label: "Data and Analytics"},
                  {value: "IoT", label: "IoT"},
                  {value: "AI/ML", label: "AI/ML"},
                  {value: "Security", label: "Security"},
                  {value: "Mainframes", label: "Mainframes"},
                  {value: "Migrations", label: "Migrations"},
                  {value: "Project/Program management", label: "Project/Program management"},
                ]
              },
              {
                component: "textarea",
                _uid: "t1_s4_q2",
                label: "What kind of IP you have? (frameworks, methodologies, accelerators, packaged solutions, etc.)",
              },
              {
                component: "textarea",
                _uid: "t1_s4_q3",
                label: "What differentiates your products/services among your competition?",
              },
              {
                component: "textarea",
                _uid: "t1_s4_q4",
                label: "What is biggest need, that AWS can help you with?",
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t1_s5",
            label: "Training & Enablement",
            fields: [
              {
                component: "textarea",
                _uid: "t1_s5_q1",
                label: "How do you develop your people across your organization?",
              },
              {
                component: "textarea",
                _uid: "t1_s5_q2",
                label: "How do you measure your staff's skills levels?",
              },
              {
                component: "textarea",
                _uid: "t1_s5_q3",
                label: "What incentives you have for your technical staff to learn and get certified?",
              },
              {
                component: "textarea",
                _uid: "t1_s5_q4",
                label: "What incentives you have for your non-technical staff to learn and get certified?",
              },
            ]
          }
        ]
      },

      {
        component: "tab",
        _uid: "t2",
        label: "Sales & Marketing",
        fields: [
          {
            component: "field_group",
            _uid: "t2_s1",
            label: "Marketing",
            fields: [
              {
                component: "textarea",
                _uid: "t2_s1_q1",
                label: "How do you build your brand?",
              },
              {
                component: "textarea",
                _uid: "t2_s1_q2",
                label: "How do you market your products/services?",
              },
              {
                component: "textarea",
                _uid: "t2_s1_q3",
                label: "How do you get leads? (Demand-Gen)",
              },
              {
                component: "textarea",
                _uid: "t2_s1_q4",
                label: "How do you convert leads into prospects and prospects into oppportunities? (Sales funnel)",
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t2_s2",
            label: "Pre-Sales",
            fields: [
              {
                component: "textarea",
                _uid: "t2_s2_q1",
                label: "Explain about your overall Pre-Sales process?",
              },
              {
                component: "textarea",
                _uid: "t2_s2_q2",
                label: "What is your process in creating SOW for a customer?",
              },
              {
                component: "radio",
                _uid: "t2_s2_q3",
                label: "Do you have dedicated Pre-Sales SA / Sales Engineering for AWS related business?",
                options: [
                  {value: "Yes", label: "Yes"},
                  {value: "No", label: "No"},
                ],
              },
              {
                component: "textarea",
                _uid: "t2_s2_q4",
                label: "What percentage of presales SAs/SEs have AWS Associate level certification?",
              },
              {
                component: "textarea",
                _uid: "t2_s2_q5",
                label: "How do you position your pre-sales staff to evangalize AWS to customers?",
              },
              {
                component: "textarea",
                _uid: "t2_s2_q6",
                label: "What percentage of presales SAs/SEs have AWS Pro/Speciality level certification?",
              },
              {
                component: "textarea",
                _uid: "t2_s2_q7",
                label: "What is your pre-sales SA/SE ratio to overall pre-sales staff?",
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t2_s3",
            label: "Sales",
            fields: [
              {
                component: "textarea",
                _uid: "t2_s3_q1",
                label: "What is the average relevant sales experience of your sales staff? ",
              },
              {
                component: "textarea",
                _uid: "t2_s3_q2",
                label: "What percentage of sales staff has AWS certification?",
              },
              {
                component: "textarea",
                _uid: "t2_s3_q3",
                label: "What is the process you follow to close on an opportunity?",
              },
              {
                component: "textarea",
                _uid: "t2_s3_q4",
                label: "What is a median turnaround time from gaining an opportunity to closing a deal?",
              },
              {
                component: "textarea",
                _uid: "t2_s3_q5",
                label: "What is the process of generating an SOW?",
              },
              {
                component: "textarea",
                _uid: "t2_s3_q6",
                label: "How long it takes to create an SOW?",
              },
              {
                component: "textarea",
                _uid: "t2_s3_q7",
                label: "Resale through Solution Provider Program? Distribution?",
              },
              {
                component: "textarea",
                _uid: "t2_s3_q8",
                label: "A potential customer is weighing your platform's services and features against several competitors. How do you differentiate your value?",
              },
            ]
          },
        ]
      },

      {
        component: "tab",
        _uid: "t3",
        label: "Service Delivery",
        fields: [
          {
            component: "field_group",
            _uid: "t3_s1",
            label: "People",
            fields: [
              {
                component: "textarea",
                _uid: "t3_s1_q1",
                label: "How many SAs?",
              },
              {
                component: "textarea",
                _uid: "t3_s1_q2",
                label: "What is the average experience of delivery SAs?",
              },
              {
                component: "textarea",
                _uid: "t3_s1_q3",
                label: "What is the average number of professional certifications of a delivery SA?",
              },
              {
                component: "textarea",
                _uid: "t3_s1_q4",
                label: "How many of your SAs have one or more Associate level AWS certifications?",
              },
              {
                component: "textarea",
                _uid: "t3_s1_q5",
                label: "How many of your SAs have one or more Pro/Speciality level AWS certifications?",
              },
              {
                component: "radio",
                _uid: "t3_s1_q6",
                label: "Typical education level of most of you SAs?",
                options: [
                  {value: "PhD", label: "PhD"},
                  {value: "Master's Degree", label: "Master's Degree"},
                  {value: "Bachelor's Degree", label: "Bachelor's Degree"},
                  {value: "High School", label: "High School"},
                ],
              },
              {
                component: "textarea",
                _uid: "t3_s1_q7",
                label: "How many project management staff you have?",
              },
              {
                component: "input",
                type: "text",
                _uid: "t3_s1_q8",
                label: "What percentage of PMs have AWS Practitioner certification?",
              },
            ]
          },
          {
            component: "field_group",
            _uid: "t3_s2",
            label: "Process",
            fields: [
              {
                component: "textarea",
                _uid: "t3_s2_q1",
                label: "Explain your delivery methodology, process or framework?",
              },
              {
                component: "textarea",
                _uid: "t3_s2_q2",
                label: "Explain automation in place for parts of your delivery process?",
              },
              {
                component: "textarea",
                _uid: "t3_s2_q3",
                label: "How do you help customers who are new to the cloud?",
              },
              {
                component: "textarea",
                _uid: "t3_s2_q4",
                label: "Do you have a standard process/methodology to follow in leading customer with cloud journey?",
              },
              {
                component: "textarea",
                _uid: "t3_s2_q5",
                label: "How do you articulate best practices, methodologies and processes among your staff?",
              },
              {
                component: "textarea",
                _uid: "t3_s2_q6",
                label: "How do you provision resources or acquire AWS accounts for your customers?",
              },
              {
                component: "textarea",
                _uid: "t3_s2_q7",
                label: "What kind of platform and customer performance metrics do you have internally? What do you provide to customers?",
              },
              {
                component: "textarea",
                _uid: "t3_s2_q8",
                label: "How do you manage the execution of your engagements with customers?",
              },
              {
                component: "textarea",
                _uid: "t3_s2_q9",
                label: "How do you mitigate risks in project execution?",
              }
            ]
          }
        ]
      },

      {
        component: "tab",
        _uid: "t4",
        label: "Technology",
        fields: [
          {
            component: "field_group",
            _uid: "t4_s1",
            label: "Domain",
            fields: [
              {
                component: "textarea",
                _uid: "t4_s1_q1",
                label: "What technology Domain you excel?",
              },
              {
                component: "textarea",
                _uid: "t4_s1_q2",
                label: "What technologies your technical staff mostly work on?",
              }
            ]
          },
          {
            component: "field_group",
            _uid: "t4_s2",
            label: "Experience",
            fields: [
              {
                component: "textarea",
                _uid: "t4_s2_q1",
                label: "What technologies your technical staff mostly work on?",
              },
              {
                component: "textarea",
                _uid: "t4_s2_q2",
                label: "What type of cloud migration projects have you worked on? Explain.",
              },
              {
                component: "textarea",
                _uid: "t4_s2_q3",
                label: "What scale of migration projects you worked on?",
              },
              {
                component: "textarea",
                _uid: "t4_s2_q4",
                label: "What kind of modernization projects you have worked on?",
              },
              {
                component: "textarea",
                _uid: "t4_s2_q5",
                label: "Do you have experience in building solutions using cloudnative and/or serverless technologies? Explain.",
              }
            ]
          },
          {
            component: "field_group",
            _uid: "t4_s3",
            label: "Cloud",
            fields: [
              {
                component: "textarea",
                _uid: "t4_s3_q1",
                label: "How do you set up landing zones for your customers?",
              }
            ]
          },
          {
            component: "field_group",
            _uid: "t4_s4",
            label: "Security",
            fields: [
              {
                component: "textarea",
                _uid: "t4_s4_q1",
                label: "How do you help a customer meet their compliance requirements?",
              },
              {
                component: "textarea",
                _uid: "t4_s4_q2",
                label: "If there was a breach of a customer's environment, what is the process you follow?",
              },
              {
                component: "textarea",
                _uid: "t4_s4_q3",
                label: "How would you learn from a previous breach in order to prevent future ones?",
              },
              {
                component: "textarea",
                _uid: "t4_s4_q4",
                label: "How do you incorporate Security in to the solutions provided to customers?",
              },

            ]
          }
        ]
      },

    ]
  }
};
