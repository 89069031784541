import React, { Fragment } from "react";
import { Multiselect, Box } from "../../../../aws-ui-components";

const Radio = ({ field, fieldChanged, fieldBlur, type, value }) => {
  return (
    <Box padding={{ top: "xs" }}>
      <div key={field._uid}>
        <Box>
          <label htmlFor={field._uid}>{field.label}</label>
        </Box>
        <Box>
          <Multiselect
            id={field._uid}
            name={field._uid}
            selectedOptions={value.answer}
            options={field.options}
            onChange={detail => {fieldChanged(field._uid, detail.detail.selectedOptions, field.label, "mselect", field.options);}}
            onBlur={() => { fieldBlur(value);}}
            deselectAriaLabel={e => "Remove " + e.label}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
          />
        </Box>
      </div>
    </Box>
  );
};

export default Radio;
