import React from "react";
import {SideNavigation} from "../../aws-ui-components";
import history from "../../history";

export default () => {
  const [activeHref, setActiveHref] = React.useState(
    history.location.pathname
  );

  function onFollowHandler(ev) {
    if (ev.detail.external !== true) {
      ev.preventDefault();
      if (ev.detail.href !== "" || ev.detail.href !== null ) {
        setActiveHref("/" + ev.detail.href.substring(1));
        history.push("/" + ev.detail.href.substring(1));
      } else {
        setActiveHref("/Home" );
      }
    }
  }

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{href: "/", text: "Home"}}
      onFollow= {onFollowHandler}
      items={items}
    />
  );
}

const items = [
  {
    type: "link",
    text: "My Partners",
    href: "/myPartners"
  },
  {
    type: "link",
    text: "My Sessions",
    href: "/mySessions"
  },
  {
    type: "link",
    text: "Search Sessions",
    href: "/searchSession"
  },
  /*{
    type: "link",
    text: "Insights",
    href: "/insights"
  },*/
  {
    type: "section",
    text: "About PWMT",
    defaultExpanded: false,
    items: [
      {
        type: "link",
        external: true,
        text: "PWMT Wiki",
        href: "https://w.amazon.com/bin/view/PWMT/"
      },
    ]
  },
  {
    type: "section",
    text: "Feedback",
    defaultExpanded: false,
    items: [
      {
        type: "link",
        external: true,
        text: "Report a Problem",
        href: "https://issues.amazon.com/issues/create?template=22a75eee-7dc7-4078-8b98-604a438e4235"
      },
      {
        type: "link",
        external: true,
        text: "Feature Request",
        href: "https://issues.amazon.com/issues/create?template=f4f850aa-7a01-4435-987c-b9f450e2ac55"
      }
    ]
  },
];

