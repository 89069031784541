import React from "react";
import Field from "./Field";
import {
  Box,
  SpaceBetween,
  Container,
  Header,
  Grid, Input
} from "../../../../aws-ui-components";
import Radio from "./Radio";
import Select from "./Select";
import MSelect from "./MSelect";
import TestArea from "./TextArea";

const FieldGroup = ({ field, fieldChanged, fieldBlur, values, disabled }) => {
  const fields = field.fields;
  return (
    <Container
      key={field._uid}
      header={<Header variant="h2">{field.label}</Header>}
    >
      {fields.map(field => {
        switch (field.component) {
          case "radio":
            return (
              <Radio
                key={field._uid}
                field={field}
                fieldChanged={fieldChanged}
                fieldBlur={fieldBlur}
                value={(values[field._uid] !== undefined)?values[field._uid]:""}
                disabled={disabled}
              />
            );
          case "select":
            return (
              <Select
                key={field._uid}
                field={field}
                fieldChanged={fieldChanged}
                fieldBlur={fieldBlur}
                value={(values[field._uid] !== undefined)?values[field._uid]:""}
                disabled={disabled}
              />
            );
          case "mselect":
            return (
              <MSelect
                key={field._uid}
                field={field}
                fieldChanged={fieldChanged}
                fieldBlur={fieldBlur}
                value={(values[field._uid] !== undefined)?values[field._uid]:""}
                disabled={disabled}
              />
            );
          case "testarea":
            return (
              <TestArea
                key={field._uid}
                field={field}
                fieldBlur={fieldBlur}
                fieldChanged={fieldChanged}
                value={(values[field._uid] !== undefined)?values[field._uid]:""}
                //disabled={disabled}
                readOnly = {disabled}
              />
            );
          default:
            return (
              <TestArea
                key={field._uid}
                field={field}
                fieldBlur={fieldBlur}
                fieldChanged={fieldChanged}
                value={(values[field._uid] !== undefined)?values[field._uid]:""}
                //disabled={disabled}
                readOnly = {disabled}
              />
            );
        }
      })}
    </Container>
  );
};

export default FieldGroup;
