import React from 'react';
import SessionHeader from "../SessionHeader";
import {Box, Tabs, Form, ColumnLayout, Container, Grid, Header, SpaceBetween, Textarea} from "../../../aws-ui-components";
import {connect} from "react-redux";
import {fetchSession, updateOpp, updateSession} from "../../../actions";
import DOppForm from '../resources/form/DOppForm';
import {Alert, Toggle} from "@amzn/awsui-components-react";
import SessionHold from "../resources/form/SessionHold";

class WBWOpp extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      opps: {
        opportunities: [],
        oppsNotes: ""
      },
      edit: false
    }

    if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
      const vals = this.props.store.sessionInfo.session.answers;
      if (vals !== undefined && vals !== null) {
        if (vals.opportunities !== undefined &&  vals.opportunities !== null) {
          let opps = [];
          opps = vals.opportunities;
          if (opps.opportunities !== undefined && opps.opportunities !== null)
            this.state.opps.opportunities =  opps.opportunities
          if (opps.oppsNotes !== undefined && opps.oppsNotes !== null)
            this.state.opps.oppsNotes =  opps.oppsNotes
        }
      }
    }
  }


  handleSave = (name, values) => {
    switch (name) {
      case "opportunities":
        this.state.opps.opportunities = [...values];
        break;
    }
    this.props.updateOpp(this).then(async()=>{
      this.props.saveToDB();
    });
  }

  handleTextChange = (name, value) => {
    this.setState({...this.state.opps, opps:{ [name]: value }});
    this.props.updateEventChange(true);
    this.props.updateOpp(this);
  }

  handleTextSave = () => {
    this.props.updateOpp(this).then(async()=>{
      this.props.saveToDB();
    });
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  handleToggle = (data) => {
    console.log("toggle: "+data)
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {

    return(
      <div>
        <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>
        <Tabs
          tabs={[
            {
              label: "Opportunities Discussion",
              id: "PR Statements",
              content: <div>
                <Box margin="l">
                  <Grid
                    gridDefinition={[
                      {colspan: {default: 12}, offset: {default: 0}}
                    ]}
                  >
                    <SpaceBetween size="s">
                      <DOppForm props={this.state.opps.opportunities} name="opportunities" handleSave={this.handleSave} updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                    </SpaceBetween>
                  </Grid>
                </Box>

              </div>
            },
            {
              label: "Notes",
              id: "Notes",
              content:   <Container>
                <Textarea
                  disabled={!this.state.edit}
                  autoComplete={false}
                  name="oppsNotes"
                  onChange={({ detail }) => this.handleTextChange("oppsNotes", detail.value)}
                  onBlur={(detail) => {detail.preventDefault(); this.handleTextSave()}}
                  value={this.state.opps.oppsNotes}
                  rows = {(this.state.opps.oppsNotes !== null)? (this.state.opps.oppsNotes.length/160 + this.state.opps.oppsNotes.split("\n").length): 1}
                  placeholder="Enter your notes here..."
                />
              </Container>
            }
          ]}
          />

      </div>
    )
  }


}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateOpp, updateSession
})(WBWOpp);