/************************************************************************
 DISCLAIMER

 This is just a playground package. It does not comply with best practices
 of using AWS-UI components. For production code, follow the integration
 guidelines:

 https://polaris.a2z.com/develop/integration/react/
 ************************************************************************/
import React, { useEffect, useState } from "react";
import {connect} from 'react-redux';
import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  DEFAULT_PREFERENCES,
  PAGE_SELECTOR_OPTIONS
} from "./resources/my-partners-table-config.jsx";
import {
  Box,
  Button,
  CollectionPreferences,
  FormField,
  Header,
  Pagination,
  RadioGroup,
  SpaceBetween,
  Table,
  TextFilter,
  Modal, Spinner
} from "../../aws-ui-components";

import { useCollection } from "@awsui/collection-hooks";
import {addPartnertoProfile, fetchMyProfile, fetchMySessions, removePartnerFromProfile} from "../../actions";
import PWMTHeader from "./MainHeader";
import CreateSession from "./CreateSession";
import history from "../../history";
import AddPartner from "./AddPartner";


const MyPartnerTable = (props) => {

  //console.log(props);

  const [partners, setPartners] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(true);
  const [partnerModal, setAddPartnerModal] = useState(false);
  const [CSModalVisible, setCSModalVisible] = useState(false);
  const [deleteSpinner, setDeleteSpinner] = useState(false);

  const {
    items,
    actions,
    collectionProps,
    filterProps,
    paginationProps,
    filteredItemsCount
  } = useCollection(partners, {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    filtering: {
      noMatch: (
        <Box textAlign="center" color="inherit">
          <b>No matches</b>
          <Box color="inherit" margin={{ top: "xxs", bottom: "s" }}>
            No results match your query
          </Box>
          <Button onClick={() => actions.setFiltering("")}>Clear filter</Button>
        </Box>
      )
    }
  });

  useEffect( async () =>  {
    if (props.myprofile !== undefined) {
      if (props.myprofile.mypartners !== undefined) {
        await setPartners(props.myprofile.mypartners);
        await setLoading(false);
      }
    }
  });

  function onClickHandler(){
    setCSModalVisible(true);
  }

  // Keeps track of how many partners are selected
  function headerCounter(selectedPartners, partners) {
    return selectedPartners.length
      ? `(${selectedPartners.length} of ${partners.length})`
      : `(${partners.length})`;
  }

  function filterCounter(count) {
    return `${count} ${count === 1 ? "match" : "matches"}`;
  }

  function dismissCSModal(){
    setCSModalVisible(false);
  }

  function addPartnerModal () {
    setAddPartnerModal(true);
  }

  function handleAddPartner () {
    props.fetchMyProfile().then(async ()=>{
      await setAddPartnerModal(false);
    });

    return true;
  }

  function dismissModal () {
    setAddPartnerModal(false);
  }

  function removePartners() {
    console.log(selectedPartner);
    setDeleteSpinner(true);
    setSelectedPartner([]);

    props.removePartnerFromProfile({spmsid:selectedPartner[0].spmsid}).then(async ()=>{
      await console.log("deleting");
      await props.fetchMyProfile().then(async ()=>{
        await setDeleteSpinner(false);
      });
    })

  }

  return (
    <div>
      <PWMTHeader />
      <Modal
        visible={deleteSpinner}
      >
        Deleting Partner...
       <Spinner size="large" />
      </Modal>

      <AddPartner handleAddPartner={handleAddPartner}
                  visible = {partnerModal}
                  onDismiss={dismissModal}/>

      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        visibleColumns={preferences.visibleContent}
        items={items}
        loading={loading}
        loadingText="Loading resources"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No resources to display.
            </Box>
            <Button onClick={addPartnerModal} > Add Partner </Button>
          </Box>
        }
        header={
            <Header
            variant="h2"
            counter={headerCounter(selectedPartner, partners)}
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <Button iconName="close" onClick={removePartners} variant="normal" disabled={!(selectedPartner.length === 1)}> Remove Partner </Button>
                <Button iconName="add-plus" onClick={addPartnerModal} variant="primary"> Add a Partner </Button>
              </SpaceBetween>
            }
            >
            My Partners
            </Header>
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: "Page size",
              options: PAGE_SELECTOR_OPTIONS
            }}
            visibleContentPreference={{
              title: "Select visible columns",
              options: CONTENT_SELECTOR_OPTIONS
            }}
            wrapLinesPreference={{
              label: "Wrap lines",
              description: "Check to see all the text and wrap the lines"
            }}
            customPreference={(value, setValue) => (
              <FormField stretch={true} label="View as">
                <RadioGroup
                  value={value}
                  onChange={({ detail }) => setValue(detail.value)}
                  items={CUSTOM_PREFERENCE_OPTIONS}
                />
              </FormField>
            )}
          />
        }
        selectedItems={selectedPartner}
        onSelectionChange={({ detail }) =>
          setSelectedPartner(detail.selectedItems)
        }
        selectionType="multi"
        pagination={<Pagination {...paginationProps} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={filterCounter(filteredItemsCount)}
            filteringPlaceholder="Search Partners"
          />
        }
      />
    </div>
  );
};


const mapStateToProps = (state) => {
  return {
    myprofile: state.myProfile,
  };
}

export default connect(mapStateToProps, {
  fetchMyProfile, addPartnertoProfile, removePartnerFromProfile
})(MyPartnerTable);
