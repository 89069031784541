import React from 'react';
import {Tabs, Box, Form, ColumnLayout, Container, Grid, Header, SpaceBetween, Textarea} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession, updatePRFAQ, updateSession} from "../../../actions";
import DQA from '../resources/form/DQA';
import {Alert, Toggle} from "@amzn/awsui-components-react";
import SessionHold from "../resources/form/SessionHold";

class WBWPRFAQ extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      prfaq: {
        CustomerFAQ: [],
        StakeholderFAQ: [],
        prfaqNotes: ""
      },
      edit: false
    }

    if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
      const vals = this.props.store.sessionInfo.session.answers;
      let prfaq = [];
      if (vals !== undefined && vals !== null) {
        if (vals.prfaq !== undefined &&  vals.prfaq !== null) {
          prfaq = vals.prfaq;
          if (prfaq.CustomerFAQ !== undefined && prfaq.CustomerFAQ !== null)
            this.state.prfaq.CustomerFAQ =  prfaq.CustomerFAQ
          if (prfaq.StakeholderFAQ !== undefined && prfaq.StakeholderFAQ !== null)
            this.state.prfaq.StakeholderFAQ =  prfaq.StakeholderFAQ
          if (prfaq.prfaqNotes !== undefined && prfaq.prfaqNotes !== null)
            this.state.prfaq.prfaqNotes =  prfaq.prfaqNotes
        }
      }
    }
  }

  handleSave = (name, values) => {
    switch (name) {
      case "CustomerFAQ":
        this.state.prfaq.CustomerFAQ = [...values];
        break;
      case "StakeholderFAQ":
        this.state.prfaq.StakeholderFAQ = [...values];
    }
    this.props.updatePRFAQ(this).then(async ()=>{
      this.props.saveToDB();
    });
  }

  handleTextChange = (name, value) => {
    this.setState({prfaq: {...this.state.prfaq, [name]: value }});
    this.props.updateEventChange(true);
  }

  handleTextSave = () => {
    this.props.updatePRFAQ(this).then(async ()=>{
      this.props.saveToDB();
    });
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {
    return (
      <div>
        <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>
        <div>
          <Tabs
            tabs={[
              {
                label: "Customer FAQ",
                id: "Customer FAQ",
                content: <div>
                  <Box margin="l">
                    <DQA props={this.state.prfaq.CustomerFAQ} name="CustomerFAQ" handleSave={this.handleSave}
                         updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                  </Box>
                </div>
              },
              {
                label: "Stakeholder FAQ",
                id: "Stakeholder FAQ",
                content:
                  <div>
                    <Box margin="l">
                      <DQA props={this.state.prfaq.StakeholderFAQ} name="StakeholderFAQ" handleSave={this.handleSave}
                           updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                    </Box>
                  </div>
              },
              {
                label: "Notes",
                id: "Notes",
                content: <Container>
                  <Textarea
                    disabled={!this.state.edit}
                    autoComplete={false}
                    name="prfaqNotes"
                    onChange={({detail}) => this.handleTextChange("prfaqNotes", detail.value)}
                    onBlur={(detail) => {
                      detail.preventDefault();
                      this.handleTextSave()
                    }}
                    value={this.state.prfaq.prfaqNotes}
                    rows={(this.state.prfaq.prfaqNotes !== null) ? (this.state.prfaq.prfaqNotes.length / 160 + this.state.prfaq.prfaqNotes.split("\n").length) : 1}
                    placeholder="Enter your notes here..."
                  />
                </Container>
              },
            ]}
          />
        </div>
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  //console.log(state);
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updatePRFAQ, updateSession
})(WBWPRFAQ);