import React from "react";
import {SideNavigation} from "../../aws-ui-components";
import history from '../../history';
import {connect} from "react-redux";

const PartnerNav =  (props) => {

  const [activeHref, setActiveHref] = React.useState(
    "ContentPartner"
  );

  function onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      setActiveHref(ev.detail.href);
      if (ev.detail.href === "/")
        history.push(ev.detail.href);
      else
        props.navChange(ev.detail.href);
    }
  }

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{href: "/", text: "Home"}}
      onFollow={onFollowHandler}
      items={items}
    />
  );
}

const items = [
  {
    type: "link-group", text: <h4>Foundational Planning</h4>,
    items: [
      {type: "link", text: "Partner Profile", href: "ContentPartner"},
      {type: "link", text: "Sessions", href: "ContentSessions"}
    ]
  },
];

/*
 {type: "link", text: "Partner Goals", href: "ContentGoals"},
      {type: "link", text: "Goals Roadmap", href: ""},
    ]
  },
  { type: "divider"},
  {
    type: "link-group", text: <h4>Partner Progress</h4>,
    items: [
      {type: "link", text: "Score Card", href: ""},
      {type: "link", text: "Progress Report", href: ""},
      {type: "link", text: "Competency Maturity Report", href: ""},
      {type: "link", text: "Other...", href: ""},
    ]
  },


  { type: "divider"},
  { type: "link", text: "Current Partner Profile", href: "ContentPartner" },

 */

const mapStateToProps = state => ({
  nav: state
});

export default connect(mapStateToProps)(PartnerNav);