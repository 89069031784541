import React from "react";
import { AppLayout } from "../../aws-ui-components";
import {connect} from 'react-redux';

import "../../styles/servicehomepage.css";
import HomeNav from "./HomeNav";
import CreateSessionContent from "./CreateSessionContent";
import {createSession, getUser} from "../../actions";
import {WBWPreQ} from "../session/wbw/resources/wbwFormv2";
import {WBPPPreQ} from "../session/wbpp/resources/WBWPreQ";
import history from "../../history";

class CreateSession extends React.Component {
  constructor(props) {
    super(props);

    this.props.getUser(this.state);
    this.state = {
      sessionName: "",
      sessionType: null,
      sessionID: "",
      partnerID: "",
      createdBy: "",
      createdByCognitoId: "",
      createdByUID: "",
      createdByEmail: "",
      createdOn: "",
      sessionForms: {},
      answers: {},
      status: 200,
      partnerInfo:{},
      loading: false,
      pid_disable: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

 componentDidMount() {
   if (this.props.spmsid !== undefined && this.props.spmsid !== null){
     this.state.partnerID = this.props.spmsid;
     this.state.pid_disable = true
   }

 }

  handleChange(name, type, event) {
    const value = type === "select" ? (  event.detail.selectedOption) : event.detail.value;

    if (name === "sessionName") {
      if (!/[^a-zA-Z0-9\-\_]/.test(value)) {
        this.setState({[name]: value});
      }
    }

    if (name === "partnerID") {
      if (!this.state.pid_disable)
      if (!/[^0-9]/.test(value)) {
        this.setState({[name]: value});
      }
    }

    if (name === "sessionType") {
        this.setState({[name]: value});
    }

  }

  handleSubmit() {
    this.setState({loading: true});
    const date = new Date();
    this.state.createdOn = date.getTime();

    this.state.createdByCognitoId = this.props.selectedSessionInfo.auth.user.username;
    this.state.createdBy = this.props.selectedSessionInfo.auth.user.attributes.given_name + " " + this.props.selectedSessionInfo.auth.user.attributes.family_name;
    this.state.createdByEmail = this.props.selectedSessionInfo.auth.user.attributes.email;
    this.state.createdByUID = this.props.selectedSessionInfo.auth.user.signInUserSession.idToken.payload.identities[0].userId;

    this.state.sessionID =
      this.state.createdByUID.toLowerCase() +
      "-" +
      this.state.sessionName.toLowerCase() +
      "-" +
      this.state.sessionType.value.toLowerCase() +
      "-" +
      this.state.createdOn;

    switch (this.state.sessionType.value.toLowerCase()){
      case "wbw":
        this.state.sessionForms= WBWPreQ;
        break;
      case "wbpp":
        this.state.sessionForms= WBPPPreQ;
        break;
    }

    delete this.state.status;
    delete this.state.loading;
    delete this.state.pid_disable;

    this.props.createSession(this.state).then(
      (resp) => {
        //console.log(resp);
        this.setState({loading:false})
        this.setState({status: resp});
        if (resp <300) history.push('/session?'+this.state.sessionID)
      }
    );
  }

  render() {
    return (
        <CreateSessionContent
          this={(this.props.selectedSessionInfo.user) ? this.props.selectedSessionInfo.user : this}
        />
    )
  }
}

const mapStateToProps = (state) => {
  return {selectedSessionInfo: state};
}

export default connect(mapStateToProps, {
  createSession, getUser
})(CreateSession);