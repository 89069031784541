export const GOAL_TYPE_OPTIONS = [
  {label: "APN Tier", value: "apn-tier" },
  {label: "APN Diff Programs", options:[
    {label: "Competency", value: "competency" },
    {label: "Service Delivery Program", value: "sdp" },
    {label: "Service Ready Program (ISV)", value: "srp" },
    {label: "Migration Competency Review (MCR)", value: "mcr" },
    {label: "Solution Provider Program", value: "spp"},
    {label: "AWS Distribution Program", value: "reseller"},
    {label: "AWS Public Sector Program", value: "psp"},
  ]},
  {label: "APN Best Practice Programs", options:[
      {label: "Well-Architected Partner Program", value: "wapp"},
      {label: "APN Immersion Day Program", value: "apnid"},
      {label: "APN Ambassador Program", value: "apn-amb"},
    ]
  },
  {label: "Partner Transformation Programs", options:[
      {label: "AWS Partner Transformation Program", value: "ptp"},
      {label: "AWS Partner Migration Program", value: "pmp"},
      {label: "SaaS Sales Alignment Program", value: "ssap"},
    ]
  },
  {label: "Partner Practice Development", options:[
      {label: "Managed Services Partner", value: "msp"},
      {label: "Packaged Offer", value: "pkg-offer"},
      {label: "re:Stack", value: "re-stack"},
    ]
  },
  {label: "Partner Practice", options:[
      {label: "Opportunities", value: "opp"},
      {label: "MAP Qualified Deals", value: "map-opp"},
    ]
  },
  {label: "Partner Marketing", options:[
      {label: "APN Blog", value: "apn-blog"},
      {label: "Immersion Days Delivered", value: "apnid-delivered"},
      {label: "Other Marketing Events", value: "mkt-events"},
    ]
  },
];