import React from 'react';
import {Box, ColumnLayout, Container, Header, Tabs, Textarea,} from "../../../aws-ui-components";
import {connect} from "react-redux";
import {fetchSession, updateSession, updateSWOT} from "../../../actions";
import DField from "../resources/form/DField";
import SessionHold from "../resources/form/SessionHold";

class WBWSWOT extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      swot: {
        strengths: [],
        weaknesses: [],
        opportunities: [],
        threats: [],
        swotNotes: ""
      },
      edit: false
    }

    if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
      const vals = this.props.store.sessionInfo.session.answers;
      let swot = [];
      if (vals !== undefined && vals !== null) {
        if (vals.swot !== undefined && vals.swot !== null) {
          swot = vals.swot;
          if (swot.strengths !== undefined && swot.strengths !== null)
            this.state.swot.strengths = swot.strengths
          if (swot.weaknesses !== undefined && swot.weaknesses !== null)
            this.state.swot.weaknesses = swot.weaknesses
          if (swot.opportunities !== undefined && swot.opportunities !== null)
            this.state.swot.opportunities = swot.opportunities
          if (swot.threats !== undefined && swot.threats !== null)
            this.state.swot.threats = swot.threats
          if (swot.swotNotes !== undefined && swot.swotNotes !== null)
            this.state.swot.swotNotes = swot.swotNotes
        }
      }
    }
  }

  handleSave = (name, values) => {
    switch (name) {
      case "Strengths":
        this.state.swot.strengths = [...values];
        break;
      case "Weaknesses":
        this.state.swot.weaknesses = [...values];
        break;
      case "Opportunities":
        this.state.swot.opportunities = [...values];
        break;
      case "Threats":
        this.state.swot.threats = [...values];
        break;
    }
    this.props.updateSWOT(this).then(async () => {
      this.props.saveToDB();
    });
  }

  handleTextChange = (name, value) => {
    this.setState({swot: {...this.state.swot, [name]: value}});
    this.props.updateEventChange(true);
    this.props.updateSWOT(this);
  }

  handleTextSave = () => {
    this.props.updateSWOT(this).then(async () => {
      this.props.saveToDB();
    });
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {


    return (
      <div>
        <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>
        <Tabs
          tabs={[
            {
              label: "Strengths & Weaknesses",
              id: "snw",
              content: <div>
                <Box margin="l">
                  <div>
                    <ColumnLayout columns={2}>
                      <Container
                        header={
                          <Header variant="h2" description="Strengths within your organization.">
                            Strengths
                          </Header>
                        }
                      >
                        <DField props={this.state.swot.strengths} name="Strengths" handleSave={this.handleSave}
                                updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                      </Container>

                      <Container
                        header={
                          <Header variant="h2" description="Weaknesses within your organization.">
                            Weaknesses
                          </Header>
                        }
                      >
                        <DField props={this.state.swot.weaknesses} name="Weaknesses" handleSave={this.handleSave}
                                updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                      </Container>
                    </ColumnLayout>
                  </div>
                </Box>
              </div>
            },
            {
              label: "Opportunities & Threats",
              id: "ont",
              content: <div>
                <Box margin="l">
                  <div>
                    <ColumnLayout columns={2}>
                      <Container
                        header={
                          <Header variant="h2"
                                  description="Opportunities outside of your organization that help grow your business.">
                            Opportunities
                          </Header>
                        }
                      >
                        <DField props={this.state.swot.opportunities} name="Opportunities" handleSave={this.handleSave}
                                updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                      </Container>

                      <Container
                        header={
                          <Header variant="h2" description="Threats from outside of your organization.">
                            Threats
                          </Header>
                        }
                      >
                        <DField props={this.state.swot.threats} name="Threats" handleSave={this.handleSave}
                                updateEventChange={this.props.updateEventChange} edit={this.state.edit}/>
                      </Container>

                    </ColumnLayout>
                  </div>
                </Box>
              </div>
            },
            {
              label: "Notes",
              id: "Notes",
              content: <Container>
                <Textarea
                  disabled={!this.state.edit}
                  autoComplete={false}
                  name="swotNotes"
                  onChange={({detail}) => this.handleTextChange("swotNotes", detail.value)}
                  onBlur={(detail) => {
                    detail.preventDefault();
                    this.handleTextSave()
                  }}
                  value={this.state.swot.swotNotes}
                  rows={(this.state.swot.swotNotes !== null) ? (this.state.swot.swotNotes.length / 160 + this.state.swot.swotNotes.split("\n").length) : 1}
                  placeholder="Enter your notes here..."
                />
              </Container>
            },
          ]}
        />

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  updateSWOT, fetchSession, updateSession
})(WBWSWOT);
