import React from "react";

import PartnerProfile from '../PartnerProfile';
import SessionUploads from '../SessionUploads';
import SessionMgmt from "../SessionMgmt";
import WBWPreQ from './WBWPreQ';
import WBWStrategy from './WBWStrategy';
import WBWNarrative from './WBWNarrative';
import PartnerCapabilities from './PartnerCapabilities'
import WBWSWOT from "../wbw/WBWSWOT";
import WBWUni from "../wbw/WBWUni";
import PartnerVision from "./PartnerVision";
import PartnerGoals from "./PartnerGoals";
import PartnerInitiatives from "./PartnerInitiatives";
import PartnerKPIs from "./PartnerKPIs";
import PartnerGTM from "./PartnerGTM";
import Stakeholders from "./Stakeholders";

export const items = [
  {
    type: "link-group", text: <h4>Partner Profile</h4>,
    items: [
      { type: "link", text: "APN Profile Snapshot", href: PartnerProfile }
    ]
  },
  {
    type: "link-group", text: <h4>Preparation</h4>,
    items: [
      {type: "link", text: "Research Material", href: ""},
      {type: "link", text: "Key Stakeholders", href: Stakeholders},
    ]
  },
  {
    type: "link-group", text: <h4>Envisioning</h4>,
    items: [
      { type: "link", text: "Partner Capabilities", href: PartnerCapabilities},
      {type: "link", text: "SWOT Analysis", href: WBWSWOT},
      {type: "link", text: "Super Powers & Growth Ideas", href: ""},
      {type: "link", text: "Vision", href: PartnerVision}
    ]
  },
  {
    type: "link-group", text: <h4>Planning</h4>,
    items: [
      {type: "link", text: "Overall Strategy", href: PartnerGoals},
      {type: "link", text: "Initiatives, Goals & KPIs", href: ""},
    ]
  },
  { type: "divider"},
  {
    type: "link-group", text: <h4>Rendered Outputs</h4>,
    items: [
      {type: "link", text: "Recommendations Narrative", href: WBWNarrative},
    ]
  },
  { type: "divider"},
  { type: "link", text: "Additional Artifacts (uploads)", href: SessionUploads },
];