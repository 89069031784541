export const WBPPPreQ = {
  wbw: {
    version: "0.2",
    preQ: [
      {
        component: "tab",
        _uid: "t1",
        label: "tab-1",
        fields: [
          {
            component: "field_group",
            _uid: "t1_s1",
            label: "Section 1",
            fields: [
              {
                component: "textarea",
                _uid: "t1_s1_q1",
                label: "question1?",
              }
            ]
          }
        ]
      },
      {
        component: "tab",
        _uid: "t2",
        label: "tab-2",
        fields: [
          {
            component: "field_group",
            _uid: "t2_s1",
            label: "Section 1",
            fields: [
              {
                component: "textarea",
                _uid: "t2_s1_q1",
                label: "question1?",
              }
            ]
          }
        ]
      }
    ]
  }
};
