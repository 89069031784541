import React from 'react';
import ReactDom from 'react-dom';
import '@amzn/awsui-global-styles/polaris.css';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import App from './components/App';
import reducers from './reducers';

import Amplify from 'aws-amplify';
import config from './aws-exports';

const store = createStore(reducers, composeWithDevTools(
  applyMiddleware(thunk)
));

Amplify.configure(config);

ReactDom.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);
