import React from 'react';
import {Tabs, Box, Container, Grid, Header, Textarea} from "../../../aws-ui-components";
import {connect} from "react-redux";

import {fetchSession, updateStr, updateSession} from "../../../actions";
import {Alert, Toggle} from "@amzn/awsui-components-react";
import SessionHold from "../resources/form/SessionHold";

class WBWStrategy extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      str: {
        overview: "",
        approach: "",
        conclusion: ""
      },
      edit:false
    }

    if (this.props.store.sessionInfo.session.answers !== undefined && this.props.store.sessionInfo.session.answers !== null) {
      const vals = this.props.store.sessionInfo.session.answers;
      let str = [];
      if (vals !== undefined && vals !== null) {
        if (vals.str !== undefined &&  vals.str !== null) {
          str = vals.str;
          if (str.overview !== undefined && str.overview !== null)
            this.state.str.overview = str.overview
          if (str.approach !== undefined && str.approach !== null)
            this.state.str.approach = str.approach
          if (str.conclusion !== undefined && str.conclusion !== null)
            this.state.str.conclusion = str.conclusion
        }
      }
    }
  }

  handleTextChange = (name, value) => {
    this.setState({str: {...this.state.str, [name]: value }});
    this.props.updateEventChange(true);
    this.props.updateStr(this);
  }
  handleTextSave = (name, value) => {
    this.props.updateStr(this).then(async()=>{
      this.props.saveToDB();
    });
  }

  componentWillUnmount() {
    this.props.saveToDB();
  }

  sessionHoldHandler = (e) => {
    this.setState({edit: e})
  }

  render() {
    return(
      <div>
        <SessionHold sessionHoldHandler={this.sessionHoldHandler} sessionID = {this.props.store.sessionInfo.session.sessionID}/>
        <Tabs
          tabs={[
            {
              label: "Executive Summary",
              id: "Executive Summary",
              content:   <div>
                <Box margin="l">
                  <Container
                  >
                    <Textarea
                      disabled={!this.state.edit}
                      autoComplete={false}
                      name="overview"
                      onChange={({ detail }) => this.handleTextChange("overview", detail.value)}
                      onBlur={(detail) => {detail.preventDefault(); this.handleTextSave("overview",detail.value)}}
                      value={this.state.str.overview}
                      placeholder="Enter Overview"
                      rows={(this.state.str.overview !== null)? (this.state.str.overview.length/160 + this.state.str.overview.split("\n").length): 1}
                    />
                  </Container>
                </Box>
              </div>
            },
            {
              label: "Strategy Approach & Focus Areas",
              id: "Strategy Approach & Focus Areas",
              content:   <div>
                <Box margin="l">
                  <Container>
                    <Textarea
                      disabled={!this.state.edit}
                      autoComplete={false}
                      name="approach"
                      onChange={({ detail }) => this.handleTextChange("approach", detail.value)}
                      onBlur={(detail) => {detail.preventDefault(); this.handleTextSave("approach",detail.value)}}
                      value={this.state.str.approach}
                      placeholder="Enter Approach"
                      rows={(this.state.str.approach !== null)? (this.state.str.approach.length/160 + this.state.str.approach.split("\n").length): 1}
                    />
                  </Container>
                </Box>
              </div>
            },
            {
              label: "Conclusion",
              id: "Conclusion",
              content:   <div>
                <Box margin="l">
                  <Container
                  >
                    <Textarea
                      disabled={!this.state.edit}
                      autoComplete={false}
                      name="conclusion"
                      onChange={({ detail }) => this.handleTextChange("conclusion", detail.value)}
                      onBlur={(detail) => {detail.preventDefault(); this.handleTextSave("conclusion",detail.value)}}
                      value={this.state.str.conclusion}
                      placeholder="Enter Conclusion"
                      rows={(this.state.str.conclusion !== null)? (this.state.str.conclusion.length/160 + this.state.str.conclusion.split("\n").length): 1}
                    />
                  </Container>
                </Box>
              </div>
            },
            ]}
          />
      </div>
    )
  }

}

const mapStateToProps = (state) => {
  return {store: state};
}

export default connect(mapStateToProps, {
  fetchSession, updateStr, updateSession
})(WBWStrategy);