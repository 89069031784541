import {
    Box,
    Container,
    Grid,
    BarChart,
  Header,
  Button
} from "../../aws-ui-components";
import MainHeader from "./MainHeader";
import React from "react";

const x = "blah\nblah";
export default props => (
    <div>
        <MainHeader />

        <Box padding={{ top: "l" }}>

            <Box textAlign="center">
                <Box variant="h1">
                    This is dummy Data!
                </Box>
                <Box>
                    We are digging our backyards for the real stuff...
                </Box>
            </Box>
            <Grid
                gridDefinition={[
                    { colspan: { default: 10 }, offset: { default: 1 } }
                ]}
            >
                <Container
                  header={
                      <Header
                        variant="h3"
                        description=""
                      >
                          Your Sessions per Month
                      </Header>
                  }
                >
                    <BarChart
                      series={[
                          {
                              title: "Number of Session per Month",
                              type: "bar",
                              data: [
                                  { x: new Date(2021, 0), y: 1 },
                                  { x: new Date(2021,2), y: 4 },
                                  { x: new Date(2021,3), y: 1 },
                                  { x: new Date(2021,4), y: 4 },
                                  { x: new Date(2021,5), y: 2 }
                              ],
                              valueFormatter: t =>
                                "$" + t.toLocaleString("en-US")
                          },
                          {
                              title: "Sessions#",
                              type: "threshold",
                              y: 10,
                              color:
                                "var(--color-charts-threshold-neutral-2ZkiuH, #687078)",
                              valueFormatter: t =>
                                "$" + t.toLocaleString("en-US")
                          }
                      ]}
                      xDomain={[
                          new Date(2021,0),
                          new Date(2021,1),
                          new Date(2021,2),
                          new Date(2021,3),
                          new Date(2021,4)
                      ]}
                      yDomain={[0, 5]}
                      i18nStrings={{
                          filterLabel: "Filter displayed data",
                          filterPlaceholder: "Filter data",
                          filterSelectedAriaLabel: "selected",
                          legendAriaLabel: "Legend",
                          chartAriaRoleDescription: "line chart",
                          xTickFormatter: t =>
                            t
                              .toLocaleDateString("en-US", {
                                  month: "short",
                                  day: "numeric",
                                  hour: "numeric",
                                  minute: "numeric",
                                  hour12: !1
                              })
                              .split(",")
                              .join("\n"),
                          yTickFormatter: t =>
                            Math.abs(t) < 1e3
                              ? t
                              : (t / 1e3).toFixed() + "k"
                      }}
                      errorText="Error loading data."
                      height={300}
                      loadingText="Loading chart"
                      recoveryText="Retry"
                      statusType="finished"
                      xScaleType="categorical"
                      xTitle="Time (months)"
                      yTitle="Number fo Sessions"
                      empty={
                          <Box textAlign="center" color="inherit">
                              <b>No data available</b>
                              <Box variant="p" color="inherit">
                                  There is no data available
                              </Box>
                          </Box>
                      }
                      noMatch={
                          <Box textAlign="center" color="inherit">
                              <b>No matching data</b>
                              <Box variant="p" color="inherit">
                                  There is no matching data to display
                              </Box>
                              <Button>Clear filter</Button>
                          </Box>
                      }
                    />
                </Container>
            </Grid>
        </Box>
    </div>
);
