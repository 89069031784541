export default (state = [], action) => {
  switch(action.type) {
    case 'FETCH_MY_SESSIONS':
      return action.payload;
    case 'FETCH_SEARCH_SESSIONS':
      return action.payload;
    case 'FETCH_SESSION':
      return action.payload;
    case 'FETCH_PARTNER':
      return action.payload;
    case 'CREATE_SESSION':
      return action.payload;
    case 'GET_SESSION_NAV':
      return action.payload;
    case 'GET_PARTNER_PROFILE':
      return action.payload;
    case 'UPDATE_SESSION_ACCESS':
    case 'UPDATE_SESSION_STATE':
      return {...state,session:{...state.session, ...action.payload}};
    case 'UPDATE_PREQ':
    case 'UPDATE_SWOT':
    case 'UPDATE_PRS':
    case 'UPDATE_PRFAQ':
    case 'UPDATE_RECOMM':
    case 'UPDATE_OPP':
    case 'UPDATE_STR':
    case 'UPDATE_UNI':
    case 'UPDATE_PREQ_UPLOAD':
    case 'UPDATE_GOALS':
      return {...state,session:{...state.session,answers:{...state.session.answers,...action.payload}}};
    case 'UPDATE_SESSION':
      return action.payload
    default:
      return state;
  }

}