import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Textarea,
  DatePicker,
  Container,
  Grid,
  SpaceBetween,
  Form, Modal,
  Multiselect
} from "../../../../aws-ui-components";
import "../../../../styles/servicehomepage.css"

const DPracticeAreas = ({props, name, handleSave, updateEventChange, edit}) => {
  const [fields, setFields] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [delIDX, setDelIDX] = useState(null);

  useEffect(() => {
    if (props !== undefined){
      setFields(props);
    }

  }, []);

  function handleChange(i,name, event) {
    const values = fields;
    values[i] = {...values[i], [name]: event} ;
    setFields([...values]);
    updateEventChange(true);
  }

  function handleAdd(e) {
    e.preventDefault();
    const values = fields;
    values.push({ what: null, why: null, When: null, rationale:null, awsinv: null, outcome: null, nextsteps: null });
    setFields([...values]);
    updateEventChange(true);
  }

  function handleRemove(i, e) {
    setDelIDX(i);
    setDelModal(true);
    updateEventChange(true);
  }

  function handleRemoveItem() {
    setDelModal(false);
    const values = fields;
    values.splice(delIDX, 1);
    setFields([...values]);
    updateEventChange(true);
  }

  return (
    <div className="App">
      <SpaceBetween direction="vertical" size="xxs">
        {
          fields.map((field, idx) => {
            return (
              <div key={`${field}-${idx}`}>
                <Modal
                  visible={delModal}
                  onDismiss={() => setDelModal(false)}
                  size="small"
                  header={<div>Delete: Are you sure?</div>}
                >
                  <Box textAlign="center">
                    <Button
                      id={`${field}-${idx}`}
                      variant="primary"
                      icon="close"
                      onClick={(detail) => {
                        handleRemoveItem();
                        handleSave(name, fields)
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                </Modal>
                <Grid
                  gridDefinition={[{ colspan: 11, offset: { default: 0 } }, { colspan: 1, offset: { default: 0 }  }]}
                >
                  <div>
                    <Container
                      // header = {<p>{name+"-"+`${idx+1}`}</p>}
                    >

                      <label>
                        Practice Area
                        <Multiselect
                          selectedOptions={field.PA}
                          onChange={({ detail }) => handleChange(idx, "rationale", detail.value)}
                          value = {field.rationale}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                        ></Multiselect>

                      </label>
                      <label>
                        Outcome
                        <Textarea
                          disabled={!edit}
                          onChange={({ detail }) => handleChange(idx, "outcome", detail.value)}
                          value = {field.outcome}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {(field.outcome !== null)? (field.outcome.length/160 + field.outcome.split("\n").length): 1}
                          placeholder="Enter details..."
                        />
                      </label>
                      <label>
                        AWS investment
                        <Textarea
                          disabled={!edit}
                          onChange={({ detail }) => handleChange(idx, "awsinv", detail.value)}
                          value = {field.awsinv}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {(field.awsinv !== null)? (field.awsinv.length/160 + field.awsinv.split("\n").length): 1}
                          placeholder="Enter details..."
                        />
                      </label>
                      <label>
                        Next Steps
                        <Textarea
                          disabled={!edit}
                          onChange={({ detail }) => handleChange(idx, "nextsteps", detail.value)}
                          value = {field.nextsteps}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {(field.nextsteps !== null)? (field.nextsteps.length/160 + field.nextsteps.split("\n").length): 1}
                          placeholder="Enter details..."
                        />
                      </label>
                    </Container>
                  </div>
                  <Box float="right">
                    {(edit === true) ?
                      <Button
                        id={`${field}-${idx}`}
                        variant="icon"
                        iconName="close"
                        onClick={(detail) => {
                          handleRemove(idx, detail);
                          handleSave(name, fields)
                        }}
                      /> : <div></div>
                    }
                  </Box>
                </Grid>
              </div>
            );
          })}
        {(edit === true) ?
          <Button iconAlign="right"
                  iconName="add-plus"
                  variant="primary"
                  onClick={(detail) => {
                    handleAdd(detail);
                    handleSave(name, fields)
                  }}
          >Add</Button> : <div></div>
        }
      </SpaceBetween>
    </div>
  );
}

export default DPracticeAreas;
