import React, { useEffect, useState, Component}  from "react";
import {connect} from 'react-redux';
import "../../styles/servicehomepage.css";
import '@amzn/awsui-global-styles/polaris.css';
import "../../styles/servicehomepage.css";
import SearchSessionsTable from "./SearchSessionsTable";

import {
  AppLayout, Button, Input, ColumnLayout, Box
} from "../../aws-ui-components";

import HomeNav from "./HomeNav";
import MainTools from "./MainTools";
import {fetchMySessions, searchSessions} from "../../actions";
import PWMTHeader from "./MainHeader";

class SearchSessions extends React.Component {
  constructor(props) {
    super(props);
    this.state ={
      searchField: "",
      loading: false
    }
  }

  setSearchField = (value) =>{
    this.setState({searchField:value})
  }

  setLoading =(value) => {
    this.setState({loading: value});
  }

  setSearchResults = (value) => {
    this.setState({searchResults: value})
  }

  handleSearchSubmit = () =>{
    this.setLoading(true);
    this.props.searchSessions("any", this.state.searchField).then(  ()=> {
      this.setLoading(false);
    });
  }


  render() {
    return (
      <AppLayout
        disableContentPaddings={true}
        navigation={<HomeNav />}
        content={
          <div>

            <PWMTHeader />
            <Box> <h1>Search Sessions</h1> </Box>
            <ColumnLayout columns={2}>
              <Input
                onChange={({ detail }) => this.setSearchField(detail.value)}
                value={this.state.searchField}
                placeholder="Enter search value (case sensitive)"
              />

              <Button variant="primary"
                      onClick={(details)=> this.handleSearchSubmit()}
              >
                Search
              </Button>
            </ColumnLayout>
            <SearchSessionsTable loading={this.state.loading}/>
          </div>
        } //props={this.state} for table2

        contentType="default"
        navigationOpen={true}
        tools={<MainTools/>}
        toolsHide={false}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    sessions: state.sessionInfo,
  };
}

export default connect(mapStateToProps, {
  searchSessions
})(SearchSessions);

