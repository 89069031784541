import React from "react";
import {Box, Grid} from "../../aws-ui-components";
import "../../styles/intro.css";
import {connect} from 'react-redux';
import {getUser, fetchSession} from "../../actions";

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
class SessionHeader extends React.Component {

  constructor(props) {
    super(props);
    this.props.getUser(this);
  }

  render() {
    let userBadge = "";
    let uid = "";
    let sessionType ="";
    let sessionName = "";
    let partnerName = "";
    let userdata = this.props.props.auth.user;

    if (userdata !== undefined) {
      uid = userdata.signInUserSession.idToken.payload.identities[0].userId;
      userBadge = "https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=" + uid;
    }

    if (this.props.props.sessionInfo.session !== undefined) {
      sessionName = this.props.props.sessionInfo.session.sessionName;
      sessionType = this.props.props.sessionInfo.session.sessionType.label;
      //partnerName = this.props.props.sessionInfo.session.partnerInfo.name;
    }

    return (

      <Grid
        className="custom-home__header"
        gridDefinition={[{
          colspan: {xl: "12", l: "12", s: "12", xxs: "12"}
        }]}
      >
        <Box padding={{vertical: "s"}}>
          <Grid
            gridDefinition={[{
              colspan: {xl: "8", l: "8", s: "8", xxs: "8"},
              offset: {default: "1"},
            },
              {
                colspan: {l: "2", s: "2", xxs: "2"},
                offset: {default: "1"},
              },
            ]}
          >
            <div className="custom-home__header-title">
              <Box variant="h1" color="inherit">
                {sessionType}
              </Box>
              <Box variant="h5" color="inherit">
                {(sessionName)? <div>Session Name: {sessionName}</div>:<div></div>}
              </Box>
            </div>

            <Box float="right">
              <img className="header-badge"  src={userBadge} alt="header-badge"/>
            </Box>

          </Grid>
        </Box>
      </Grid>
    );
  };
};

const mapStateToProps = state => ({
  props: state,
});

export default connect(mapStateToProps, {
  getUser, fetchSession
})(SessionHeader);
