import React from 'react';
import SessionHeader from "../SessionHeader";
import {Box, Table, Header} from "../../../aws-ui-components";
import {connect} from "react-redux";
import {fetchSession} from "../../../actions";
import {Container} from "@amzn/awsui-components-react";

class WBWNotes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
       SWOTNotes: "",
       UNINotes: "",
       OPPSNotes: "",
       PRSNotes: "",
       PRRFAQNotes: "",
    }
    this.props.turnOffFlashBar();
  }

  render() {

    let SWOTNotes = "";
     let  UNINotes = ""
     let  OPPSNotes = "";
      let PRSNotes = "";
      let PRFAQNotes = "";

    if( (this.props.selectedSessionInfo) !== undefined) {
      if (this.props.selectedSessionInfo.sessionInfo !== undefined) {
        if (this.props.selectedSessionInfo.sessionInfo.session !== undefined) {
          if (this.props.selectedSessionInfo.sessionInfo.session.answers !== undefined) {
            const answers = this.props.selectedSessionInfo.sessionInfo.session.answers;
            if (answers.swot !== undefined)
              if (answers.swot.swotNotes !== undefined)
                SWOTNotes = answers.swot.swotNotes;
            if (answers.uni !== undefined)
              if (answers.uni.uniNotes !== undefined)
                UNINotes= answers.uni.uniNotes;
            if (answers.opportunities !== undefined)
              if (answers.opportunities.oppsNotes !== undefined)
                OPPSNotes= answers.opportunities.oppsNotes;
            if (answers.prs !== undefined)
              if (answers.prs.prstmtNotes !== undefined)
                if (answers.prs.prstmtNotes.length > 0)
                  PRSNotes= answers.prs.prstmtNotes;
            if (answers.prfaq !== undefined)
              if (answers.prfaq.prfaqNotes !== undefined)
                PRFAQNotes = answers.prfaq.prfaqNotes;
          }
        }
      }
    }
    return(
      <div>
        <Box margin="l">
            <div>
              <Container
                header={
                  <Header variant="h3" description="">
                    Notes
                  </Header>
                }>
                {(SWOTNotes.length > 0) ?
                  <div>
                    <Box>
                      Noted from SWOT Analysis activity:
                    </Box>
                    <Box>
                      {NewlineText(SWOTNotes)}
                    </Box>
                    <p/>
                  </div> : ""
                }

                {(UNINotes.length > 0) ?
                  <div>
                    <Box>
                      Noted from Unicorn Superpowers activity:
                    </Box>
                    <Box>
                      {NewlineText(UNINotes)}
                    </Box>
                    <p/>
                  </div> : ""
                }

                {(OPPSNotes.length > 0) ?
                  <div>
                    <Box>
                      Noted from GTM Opportunities activity:
                    </Box>
                    <Box>
                      {NewlineText(OPPSNotes)}
                    </Box>
                    <p/>
                  </div> : ""
                }

                {(PRSNotes.length > 0) ?
                  <div>
                    <Box>
                      Noted from PR Statements activity:
                    </Box>
                    <Box>
                      {NewlineText(PRSNotes)}
                    </Box>
                    <p/>
                  </div> : ""
                }

                {(PRFAQNotes.length > 0) ?
                  <div>
                    <Box>
                      Noted from FAQ activity:
                    </Box>
                    <Box>
                      {NewlineText(PRFAQNotes)}
                    </Box>
                    <p/>
                  </div> : ""
                }

              </Container>
            </div>
        </Box>
      </div>
    )
  }

}


function NewlineText(props) {
  let newText = "";
  if(props !== null && props !== "" && props !== undefined) {
    const text = props;
    newText = text.split('\n').map((str, index) => <div style={{ color:"brown" }} key={index.toString()}>{str}</div>);
  }
  return newText;
}

const mapStateToProps = (state) => {
  return {selectedSessionInfo: state};
}

export default connect(mapStateToProps, {
  fetchSession
})(WBWNotes);