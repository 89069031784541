import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Textarea,
  Container,
  Grid,
  SpaceBetween,
  Form, Modal
} from "../../../../aws-ui-components";
import "../../../../styles/servicehomepage.css"

const DQA = ({props, name, handleSave, updateEventChange, edit}) => {
  const [fields, setFields] = useState([]);
  const [delModal, setDelModal] = useState(false);
  const [delIDX, setDelIDX] = useState(null);

  useEffect(() => {
    if (props !== undefined){
      setFields(props);
    }
  }, []);

  function handleChange(i,name, event) {
    const values = fields;
    values[i] = {...values[i], [name]: event} ;
    setFields([...values]);
    updateEventChange(true);
  }

  function handleAdd(e) {
    e.preventDefault();
    const values = fields;
    values.push({ question: null, answer: null });
    setFields([...values]);
    updateEventChange(true);
  }

  function handleRemove(i, e) {
    setDelIDX(i);
    setDelModal(true);
    updateEventChange(true);
  }

  function handleRemoveItem() {
    setDelModal(false);
    const values = fields;
    values.splice(delIDX, 1);
    setFields([...values]);
    updateEventChange(true);
  }

  return (
    <div className="App">
        <SpaceBetween direction="vertical" size="xxs">
          {
            fields.map((field, idx) => {
            return (
              <div key={`${field}-${idx}`}>
                <Modal
                  visible={delModal}
                  onDismiss={() => setDelModal(false)}
                  size="small"
                  header={<div>Delete: Are you sure?</div>}
                >
                  <Box textAlign="center">
                    {(edit === true) ?
                      <Button
                        id={`${field}-${idx}`}
                        variant="primary"
                        icon="close"
                        onClick={(detail) => {
                          handleRemoveItem();
                          handleSave(name, fields)
                        }}
                      >
                        Delete
                      </Button> : <div></div>
                    }
                  </Box>
                </Modal>
                <Grid
                  gridDefinition={[{ colspan: 11, offset: { default: 0 } }, { colspan: 1, offset: { default: 0 }  }]}
                >
                  <div>
                    <Container>
                      <label>
                        Question:
                        <Textarea
                          disabled={!edit}
                          onChange={({ detail }) => handleChange(idx, "question", detail.value)}
                          value = {field.question}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {(field.question !== null)? (field.question.length/160 + field.question.split("\n").length): 1}
                          placeholder="Enter your Question here..."
                        />
                      </label>
                      <label>
                        Answer:
                        <Textarea
                          disabled={!edit}
                          onChange={({ detail }) => handleChange(idx, "answer", detail.value)}
                          value = {field.answer}
                          onBlur = {(detail) => {detail.preventDefault(); handleSave(name, fields)}}
                          rows = {(field.answer !== null)? (field.answer.length/160 + field.answer.split("\n").length): 1}
                          placeholder="Enter your Answer here..."
                        />
                      </label>
                    </Container>
                  </div>
                <Box float="right">
                  {(edit === true) ?
                    <Button
                      id={`${field}-${idx}`}
                      variant="icon"
                      iconName="close"
                      onClick={(detail) => {
                        handleRemove(idx, detail);
                        handleSave(name, fields)
                      }}
                    /> : <div></div>
                  }
                </Box>
                </Grid>
              </div>

            );
          })}
          {(edit === true) ?
            <Button iconAlign="right"
                    iconName="add-plus"
                    variant="primary"
                    onClick={(detail) => {
                      handleAdd(detail);
                      handleSave(name, fields)
                    }}
            >Add</Button> : <div></div>
          }
        </SpaceBetween>
    </div>
  );
}

export default DQA;
