import React from 'react';
import history from '../../history';
import {
  Box,
  ColumnLayout,
  Container,
  Link,
  Modal,
  SpaceBetween,
  Spinner,
  StatusIndicator,
  Header
} from "../../aws-ui-components";
import {connect} from "react-redux";
import {fetchPartnerInfo} from "../../actions";

class PartnerProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      partnerInfo:{
        ace_eligibility: "",
        competency_membership: "",
        description: "",
        id: "",
        opps_count: 0,
        partner_account_name: "",
        partner_al_email: "",
        partner_apn_tier_code: "",
        partner_aws_support_level: "",
        partner_external_revenue_amount: "",
        partner_head_count: "",
        partner_industry_name: "",
        partner_onboarding_and_fulfillment_status_code: "",
        partner_pdm_name: "",
        partner_spms_id: "",
        partner_target_client_base: "",
        partner_website_name: "",
        program_membership: "",
        service_delivery_membership: "",
        well_architected_program_membership: ""
      },
      loading: true
    }
  }

  componentDidMount() {
    this.props.fetchPartnerInfo(this.props.spmsid).then(()=>{
      this.setState({...this.state, partnerInfo:this.props.store.partnerInfo, loading:false});
    });
  }

  render() {
    let partnerName = this.state.partnerInfo.name || "No info.";
    let partnerTier = this.state.partnerInfo.tier || "";
    let aceEligible = this.state.partnerInfo.ace_eligibility || "No";
    let statusAce = (this.state.partnerInfo.ace_eligibility === "Yes") ? "success" : "error";
    let spmid = this.state.partnerInfo.spmsid || "";
    let partnerWebsite = this.state.partnerInfo.partner_website || "";
    let description = this.state.partnerInfo.description || ""
    let sdps = this.state.partnerInfo.sdps || []
    let competencies = this.state.partnerInfo.competencies || [];
    let PPBID = "";
    let sfdcID = this.state.partnerInfo.sfdc_id || "";
    let programs = this.state.partnerInfo.programs || [];
    let sfdc_url = "https://aws-crm.my.salesforce.com/" + sfdcID || "";

    const style = {
      position: 'absolute',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }

    if (this.state.loading) {
      return (
        <div>
          <Modal
            visible={true}
            size="small"
            header={<div style={style}> Loading... </div>}
          >
            <div style={style}><Spinner size="normal"/></div>
          </Modal>
        </div>
      )
    } else
      return (
        <div>
          <Box padding={{top: "l"}} margin="l">
            <div>
              <SpaceBetween size="m">
                <div>
                  <SpaceBetween size="m">
                    <Container
                      header={
                        <Header
                          variant="h3"
                          description=""
                        >
                          Partner Profile
                        </Header>
                      }
                    >
                      <SpaceBetween size="l">
                        <div>
                          <ColumnLayout columns={3} variant="text-grid">
                            <SpaceBetween size="l">
                              <ValueWithLabel label="Partner Name"> {partnerName} </ValueWithLabel>
                              <ValueWithLabel label="SPMS ID">
                                {spmid}
                              </ValueWithLabel>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                              <ValueWithLabel label="Tier">{partnerTier} Tier</ValueWithLabel>
                              <ValueWithLabel label="ACE Eligiblity">
                                <StatusIndicator type={statusAce}> {aceEligible}</StatusIndicator>
                              </ValueWithLabel>
                            </SpaceBetween>
                            <SpaceBetween size="l">
                              <ValueWithLabel label="SFDC URL">
                                <Link
                                  href={sfdc_url}
                                  external={true}
                                >
                                  AWS CRM
                                </Link>
                              </ValueWithLabel>
                              <ValueWithLabel label="Partner Website">
                                <Link
                                  href={partnerWebsite}
                                  external={true}
                                >
                                  {partnerName} website
                                </Link>
                              </ValueWithLabel>
                            </SpaceBetween>
                          </ColumnLayout>
                        </div>
                        <Container
                          header={
                            <Header
                              variant="h4"
                              description=""
                            >
                              Partner Summary:
                            </Header>
                          }
                        >
                          {description}
                        </Container>

                        <Container
                          header={
                            <Header
                              variant="h4"
                              description=""
                            >
                              Partner Designations and Programs:
                            </Header>
                          }
                        >
                          <ColumnLayout columns={3} variant="text-grid">
                            <div>
                              <h5> Service Deliver Designations </h5>
                              {
                                (sdps.length > 0)?
                                  sdps.map((item, index) => {
                                    return (<Box key={index}>
                                      <StatusIndicator type="success">{item}</StatusIndicator>
                                    </Box>)
                                  }) : (<p style={{color:"Tomato"}}>none</p>)
                              }
                            </div>
                            <div>
                              <h5>Competency Designations</h5>
                              {
                                (competencies.length >0)?
                                competencies.map((item, index) => {
                                  return (<Box key={index}>
                                    <StatusIndicator type="success">{item}</StatusIndicator>
                                  </Box>)
                                }): (<p style={{color:"Tomato"}}>none</p>)
                              }
                            </div>
                            <div>
                              <h5>APN Programs</h5>
                              {
                                (programs.length > 0)?
                                programs.map((item, index) => {
                                  return (<Box key={index}>
                                    <StatusIndicator type="success">{item}</StatusIndicator>
                                  </Box>)
                                }): (<p style={{color:"Tomato"}}>none</p>)
                              }
                            </div>
                          </ColumnLayout>
                        </Container>
                        <Container
                          header={
                            <Header
                              variant="h4"
                              description=""
                            >
                              Additional Partner Data (Links):
                            </Header>
                          }
                        >
                          <Link
                            href={"https://pipeline.builder.apn.a2z.com/#/search"}
                            external={true}
                          >
                            Pipeline Builder portal Link for {partnerName}
                          </Link>
                          <p/>
                          <Link
                            //href={"https://discover.awspartner.com/partner/" + sfdcID +"/"+partnerName}
                            href={"https://discover.awspartner.com/?kw=" + partnerName + "&type=consultingPartners"}
                            external={true}
                          >
                            Partner Discovery portal Link for {partnerName}
                          </Link>
                        </Container>

                      </SpaceBetween>

                    </Container>

                  </SpaceBetween>
                </div>
              </SpaceBetween>
            </div>
          </Box>
        </div>
      )
  }

}

const ValueWithLabel = ({label, children}) => (
  <div>
    <Box margin={{bottom: 'xxxs'}} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

const dateConvert = (value) => {
  const d = new Date(value);
  return d.toUTCString();
}

const mapStateToProps = (state) => {
  return {
    store: state.partnerInfo
  };
}
export default connect(mapStateToProps, {
  fetchPartnerInfo
})(PartnerProfile);