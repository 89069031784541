/************************************************************************
 DISCLAIMER

 This is just a playground package. It does not comply with best practices
 of using AWS-UI components. For production code, follow the integration
 guidelines:

 https://polaris.a2z.com/develop/integration/react/
 ************************************************************************/
import React, { useEffect, useState } from "react";
import {connect} from 'react-redux';
import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  DEFAULT_PREFERENCES,
  PAGE_SELECTOR_OPTIONS
} from "./resources/my-sessions-table-config";
import {
  Box,
  Button,
  CollectionPreferences,
  FormField,
  Header,
  Pagination,
  RadioGroup,
  SpaceBetween,
  Table,
  TextFilter,
  Modal, ColumnLayout, Input
} from "../../aws-ui-components";

import { useCollection } from "@awsui/collection-hooks";
import {searchSessions} from "../../actions";


const SearchSessionsTable = (props) => {

  const [selectedSessions, setSelectedSessions] = useState([]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);

  const {
    items,
    collectionProps,
    paginationProps,
  } = useCollection(props.sessions.search_sessions || [], {
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
  });

  function headerCounter(selectedSessions, sessions) {
    return selectedSessions.length
      ? `(${selectedSessions.length} of ${sessions.length})`
      : `(${sessions.length})`;
  }

  return (
    <div>
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        visibleColumns={preferences.visibleContent}
        stickyHeader={true}
        items={items}
        loading={props.loading}
        loadingText="Loading results"
        filter = {
          <Header
            variant="h3"
            counter={headerCounter(selectedSessions, props.sessions.search_sessions || [])}
          >
            Search Results
          </Header>
        }

        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: "Page size",
              options: PAGE_SELECTOR_OPTIONS
            }}
            visibleContentPreference={{
              title: "Select visible columns",
              options: CONTENT_SELECTOR_OPTIONS
            }}
            wrapLinesPreference={{
              label: "Wrap lines",
              description: "Check to see all the text and wrap the lines"
            }}
            customPreference={(value, setValue) => (
              <FormField stretch={true} label="View as">
                <RadioGroup
                  value={value}
                  onChange={({ detail }) => setValue(detail.value)}
                  items={CUSTOM_PREFERENCE_OPTIONS}
                />
              </FormField>
            )}
          />
        }
        wrapLines={preferences.wrapLines}
        selectedItems={selectedSessions}
        onSelectionChange={({ detail }) =>
          setSelectedSessions(detail.selectedItems)
        }
        //selectionType="multi"
        pagination={<Pagination {...paginationProps} />}
      />
    </div>
  );
};



const mapStateToProps = (state) => {
  return {
    sessions: state.sessionInfo
  }
}

export default connect(mapStateToProps, {
  searchSessions
})(SearchSessionsTable);
